import { useContext, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useUIQuery } from "../queries";
import { getLatLngFromSearchParamsOrBoundary } from "../utils";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { LinkButton } from "../components/LinkButton";
import { BoundaryResponse } from "../responseTypes";
import { Popup } from "../components/Popup";
import { BoundarySelectSideMenu } from "./BoundarySelectSideMenu";
import { MapContext } from "../App";
import { useNavigate, useLocation } from "react-router-dom";
import { FeatureFlagged } from "../components/FeatureFlag";
import { useSatelliteVisibility } from "./BoundaryView";

function BoundaryPopupInner({ boundary }: { boundary: BoundaryResponse }) {
  return (
    <>
      <List dense>
        <ListItem>
          <ListItemText
            primary={boundary.name}
            secondary={`${boundary.state_name} ${boundary.country_name}`}
          />
        </ListItem>
        <FeatureFlagged flag="boundary.popup.show.date">
          <ListItem>
            <ListItemText
              primary={
                <>
                  <strong>Date:</strong>{" "}
                  {boundary?.date_added
                    ? new Date(boundary.date_added).toLocaleDateString()
                    : "-"}
                </>
              }
            />
          </ListItem>
        </FeatureFlagged>
        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>Target:</strong> {boundary.target_name}
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                <strong>Area:</strong>{" "}
                {boundary.area
                  ? (parseFloat(boundary.area) / 10000).toFixed(2) + " ha"
                  : "Unknown"}
              </>
            }
          />
        </ListItem>
      </List>
      <Box display="flex" justifyContent="center">
        <LinkButton to={`/boundary/view/${boundary.id}`}>View Data</LinkButton>
      </Box>
    </>
  );
}
export function BoundaryPopup() {
  const { boundaryId } = useParams();
  const { mapState } = useContext(MapContext);
  useSatelliteVisibility(mapState, "satellite");
  const location = useLocation();
  const navigate = useNavigate();
  const { data: boundary } = useUIQuery<BoundaryResponse>(
    ["boundary", { id: boundaryId }],
    true,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );
  const [searchParams] = useSearchParams();
  const [lat, lng] = useMemo(() => {
    return getLatLngFromSearchParamsOrBoundary(boundary, searchParams);
  }, [boundary, searchParams]);
  return (
    <>
      <BoundarySelectSideMenu />
      {boundary && !location.state?.closedPopup ? (
        <Popup
          map={mapState}
          element={<BoundaryPopupInner boundary={boundary} />}
          lat={lat}
          lng={lng}
          flyTo
          closable
          onClose={() => {
            navigate(location, { state: { closedPopup: true } });
          }}
        />
      ) : null}
    </>
  );
}
