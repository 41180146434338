import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Tab,
  Tabs,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useCallback } from "react";
import { useFeatureFlags } from "./FeatureFlag";
import { useLocation, useSearchParams } from "react-router-dom";
import { useContent } from "./MarkdownContent";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { TabPanel } from "../routes/BoundaryView";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { documentationUrl, videoUrl } from "../env";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: React.ReactNode;
  href?: string;
  value?: string;
  icon?: string | React.ReactElement;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab component="a" target="_blank" rel="noopener noreferrer" {...props} />
  );
}

const HELP_PAGE_UNKNOWN = `
#### Unknown Page

We haven't documented this page yet - sorry! Please click on the Get Support tab to let us know what you were trying to do and we'll get back to you as soon as we can.
`;

export function HelpModal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue && !newValue.startsWith("external")) {
      searchParams.set("helpTab", newValue);
      setSearchParams(searchParams);
    }
  };
  const tab = searchParams.get("tab");
  const helpTab = searchParams.get("helpTab") || "documentation";
  // get current route match from react router
  const location = useLocation();
  let page = "unknown";
  const { isAuthenticated } = useAuth0();
  const [
    isBoundaryEnabled,
    isAcquisitionsEnabled,
    isImageryEnabled,
    isDownloadEnabled,
    isProjectsEnabled,
    isAnalysisEnabled,
  ] = useFeatureFlags([
    "boundary.tab.view",
    "acquisitions.tab.view",
    "imagery.tab.view",
    "download.tab.view",
    "projects.tab.view",
    "analysis.tab.view",
  ]).flagList;
  // where am I?
  if (!isAuthenticated) {
    page = "anonymous";
  } else if (location.pathname === "/") {
    page = "boundarySelect";
  } else if (location.pathname.startsWith("/boundary/view")) {
    if (!tab) {
      if (isBoundaryEnabled) {
        // disabled for now, need to put back when there is clients that use this.
        page = "boundaryTab";
      } else if (isAcquisitionsEnabled) {
        // disabled for now, need to put back when there is clients that use this.
        page = "acquisitionsTab";
      } else if (isImageryEnabled) {
        page = "imageryTab";
      } else if (isDownloadEnabled) {
        page = "downloadTab";
      } else if (isProjectsEnabled) {
        page = "projectsTab";
      } else if (isAnalysisEnabled) {
        page = "analysisTab";
      }
    } else {
      page = `${tab}Tab`;
    }
  } else if (location.pathname.startsWith("/boundary/popup")) {
    page = "boundarySelect";
  } else {
    page = "unknown";
  }

  const content = useContent();
  const pageContent =
    content?.[`${page}.help`]?.trim() || HELP_PAGE_UNKNOWN.trim();
  const help = searchParams.get("help");
  const onClose = useCallback(
    () =>
      window.setTimeout(() => {
        searchParams.delete("help");
        searchParams.delete("helpTab");
        setSearchParams(searchParams);
      }, 0),
    [searchParams, setSearchParams],
  );

  const supportSearchParams = new URLSearchParams(searchParams);
  supportSearchParams.set("helpTab", "support");
  const feedbackSearchParams = new URLSearchParams(searchParams);
  feedbackSearchParams.set("helpTab", "feedback");
  const linkToSupport =
    "https://arborcarbon.atlassian.net/servicedesk/customer/portal/1/group/5/create/2";
  const linkToFeedback =
    "https://arborcarbon.atlassian.net/servicedesk/customer/portal/1/group/5/create/4";
  const supportLinkParams = { target: "_blank", rel: "noopener noreferrer" };
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={help === "true"}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        ArborCarbon Help
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            value={helpTab}
            onChange={handleChange}
            aria-label="Help Tabs"
          >
            <Tab
              value={"documentation"}
              label="Documentation"
              {...a11yProps(0)}
            />
            {videoUrl && (
              <Tab value={"video"} label="Tutorial Video" {...a11yProps(5)} />
            )}
            <Tab value={"faq"} label="FAQs" {...a11yProps(1)} />
            {documentationUrl && (
              <LinkTab
                value={"externalPdf"}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    PDF Documentation{" "}
                    <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
                  </Box>
                }
                {...a11yProps(4)}
                href={documentationUrl}
              />
            )}
            <LinkTab
              value={"externalFeedback"}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Give Feedback{" "}
                  <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
                </Box>
              }
              {...a11yProps(2)}
              href={linkToFeedback}
            />
            <LinkTab
              value={"externalSupport"}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Get Support <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
                </Box>
              }
              {...a11yProps(3)}
              href={linkToSupport}
            />
          </Tabs>
        </Box>
        <TabPanel value={helpTab} index={"documentation"}>
          <Box
            sx={{
              height: "800px",
              maxHeight: "70vh",
              "& li ul": {
                mb: 0,
                pb: 0,
              },
              "& p": {
                mb: 1,
              },
              "& ul": {
                mb: 1,
              },
              "& ol": {
                mb: 1,
              },
              "& h1": {
                mb: 2,
                mt: 3,
              },
              "& h2": {
                mb: 2,
                mt: 3,
              },
              "& h3": {
                mb: 2,
                mt: 3,
              },
              "& h4": {
                mb: 2,
                mt: 0,
              },
              "& h5": {
                mb: 2,
                mt: 3,
              },
              "& h6": {
                mb: 2,
                mt: 3,
              },
              "& img": {
                width: "75%",
                // when below 1000px wide, make images 100% width
                "@media (max-width: 1000px)": {
                  width: "100%",
                },
                ml: "auto",
                mr: "auto",
                mt: 4,
                mb: 4,
                display: "block",
              },
            }}
          >
            <Box>
              <MuiMarkdown
                overrides={{
                  ...getOverrides({}),
                  a: {
                    props: {
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  ul: List,
                  li: ({ children }) => (
                    <ListItem sx={{ mt: 0, mb: 0, pt: 0, pb: 0 }}>
                      <ListItemText>{children}</ListItemText>
                    </ListItem>
                  ),
                }}
              >
                {pageContent}
              </MuiMarkdown>
              <Typography component="h5" variant="h5">
                Need more help?
              </Typography>
              <Typography component="p" variant="body1">
                If you need more help, please go to{" "}
                <Link to={linkToSupport} {...supportLinkParams}>
                  Get Support
                </Link>{" "}
                and fill out a request.
              </Typography>
              <Typography component="h5" variant="h5">
                How are we doing?
              </Typography>
              <Typography component="p" variant="body1">
                Please tell let us know by{" "}
                <Link to={linkToFeedback} {...supportLinkParams}>
                  giving us feedback
                </Link>
                .
              </Typography>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={helpTab} index={"faq"}>
          <Box
            sx={{
              height: "800px",
              maxHeight: "70vh",
              "& p": {
                mb: 1,
              },
              "& ul": {
                mb: 1,
              },
              "& ol": {
                mb: 1,
              },
              "& h1": {
                mb: 2,
                mt: 3,
              },
              "& h2": {
                mb: 2,
                mt: 3,
              },
              "& h3": {
                mb: 2,
                mt: 3,
              },
              "& h4": {
                mb: 2,
                mt: 0,
              },
              "& h5": {
                mb: 2,
                mt: 3,
              },
              "& h6": {
                mb: 2,
                mt: 3,
              },
              "& img": {
                width: "75%",
                // when below 1000px wide, make images 100% width
                "@media (max-width: 1000px)": {
                  width: "100%",
                },
                ml: "auto",
                mr: "auto",
                mt: 4,
                mb: 4,
                display: "block",
              },
            }}
          >
            <Box>
              <Typography component="h4" variant="h4">
                Frequently Asked Questions
              </Typography>
              <Typography component="h5" variant="h5">
                How can I share or save my results?
              </Typography>
              <Typography component="p" variant="body1">
                The {process.env.REACT_APP_SITE_TITLE} is designed to be used in
                a web browser. You can bookmark the page to save your specific
                data configuration. You can also share the URL with others to
                share your results, as long as they have an account and access
                to the same data.
              </Typography>
              <Typography component="h5" variant="h5">
                I have forgotten my password
              </Typography>
              <Typography component="p" variant="body1">
                Please click Login and then "Forgot Password?" to reset your
                password.
              </Typography>
              <Typography component="h5" variant="h5">
                I can't find the left panel
              </Typography>
              <Typography component="p" variant="body1">
                The left panel can be hidden by clicking the X in the top left
                corner of the page. To show the left panel again, click the
                hamburger menu in the top left corner of the page.
              </Typography>
              <Typography component="h5" variant="h5">
                I would like to report a bug/performance issue
              </Typography>
              <Typography component="p" variant="body1">
                Please go to{" "}
                <Link to={linkToSupport} {...supportLinkParams}>
                  Get Support
                </Link>{" "}
                and fill out a request. In the mean-time, if you encounter
                issues, refreshing the page may help.
              </Typography>
              <Typography component="h5" variant="h5">
                I can't log in / Something is missing / I would like access / I
                would like to give access to someone else
              </Typography>
              <Typography component="p" variant="body1">
                Please go to{" "}
                <Link to={linkToSupport} {...supportLinkParams}>
                  Get Support
                </Link>{" "}
                and fill out a request.
              </Typography>
              <Typography component="h5" variant="h5">
                I have a feature request
              </Typography>
              <Typography component="p" variant="body1">
                Please tell let us know by{" "}
                <Link to={linkToFeedback} {...supportLinkParams}>
                  filling out this form
                </Link>
                .
              </Typography>
              <Typography component="h5" variant="h5">
                Need more help?
              </Typography>
              <Typography component="p" variant="body1">
                If you need more help, please go to{" "}
                <Link to={linkToSupport} {...supportLinkParams}>
                  Get Support
                </Link>{" "}
                and fill out a request.
              </Typography>
              <Typography component="h5" variant="h5">
                How are we doing?
              </Typography>
              <Typography component="p" variant="body1">
                Please tell let us know by{" "}
                <Link to={linkToFeedback} {...supportLinkParams}>
                  giving us feedback
                </Link>
                .
              </Typography>
            </Box>
          </Box>
        </TabPanel>
        {videoUrl && (
          <TabPanel value={helpTab} index={"video"}>
            <Box sx={{ height: "800px" }}>
              <Typography sx={{ mb: 2 }} component="h4" variant="h4">
                Tutorial Video
              </Typography>
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "500px",
                  objectFit: "contain",
                }}
                controls
                src={videoUrl}
                preload="auto"
              />
            </Box>
          </TabPanel>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
