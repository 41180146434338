export const djangoSubdomain = "uiapi";
export const cogSubdomain = "tiles";
export const mvtSubdomain = "mvt";
export const authSubdomain = "auth";
export const environment = process.env.REACT_APP_ENVIRONMENT;
export const useEnvironmentInDomain =
  process.env.REACT_APP_USE_ENVIRONMENT_IN_DOMAIN || true;
export const baseDomain = useEnvironmentInDomain
  ? `${environment}.${process.env.REACT_APP_BASE_DOMAIN}`
  : process.env.REACT_APP_BASE_DOMAIN;
export const djangoUrl =
  process.env.REACT_APP_DJANGO_URL ||
  `https://${djangoSubdomain}.${baseDomain}`;
export const actilesCogDomain =
  process.env.REACT_APP_COG_DOMAIN || `${cogSubdomain}.${baseDomain}`;
export const actilesMvtDomain =
  process.env.REACT_APP_MVT_DOMAIN || `${mvtSubdomain}.${baseDomain}`;
export const actilesProto = process.env.REACT_APP_API_PROTO || "https";

export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";
export const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN || "";
export const mapboxStyle = process.env.REACT_APP_MAPBOX_STYLE || "";
export const documentationUrl =
  process.env.REACT_APP_DOCUMENTATION_PDF_URL || "";
export const videoUrl = process.env.REACT_APP_VIDEO_URL || "";
