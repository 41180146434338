import { useAuth0 } from "@auth0/auth0-react";
import { useAccessToken, useUIQuery } from "../queries";
import { ContentResponse } from "../responseTypes";
import { List, Typography } from "@mui/material";
import MuiMarkdown, { getOverrides } from "mui-markdown";

export function useContent() {
  const { user } = useAuth0();
  const token = useAccessToken();
  const { data: strings } = useUIQuery<ContentResponse>(
    [
      "content",
      {
        extraKey: token ? user?.sub : "anonymous",
      },
    ],
    true,
    "GET",
    {
      // 1 day
      staleTime: 1000 * 60 * 60 * 24,
    },
    false,
  );
  return strings;
}
export function Markdown({ content }: { content: string }) {
  return (
    <MuiMarkdown
      overrides={{
        ...getOverrides({}),
        h4: {
          component: Typography,
          props: {
            variant: "h4",
            sx: {
              mb: 0,
            },
          },
        },
        p: {
          component: Typography,
          props: {
            component: "p",
            variant: "body1",
            sx: {
              mt: 2,
            },
          },
        },
        a: {
          props: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        ul: List,
        ol: {
          component: Typography,
          props: {
            component: "ol",
            variant: "body1",
            sx: {
              mt: 0,
            },
          },
        },
        li: {
          component: Typography,
          props: {
            component: "li",
            variant: "body1",
          },
        },
      }}
    >
      {content.trim()}
    </MuiMarkdown>
  );
}
export function MarkdownContent({
  contentKey,
}: {
  contentKey: keyof ContentResponse;
}) {
  const content = useContent()?.[contentKey];
  if (content) {
    return <Markdown content={content} />;
  }
  return null;
}
