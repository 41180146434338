import { List, Typography, ListItemText, Skeleton } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useUIQuery } from "../../queries";
import ListItemButton from "@mui/material/ListItemButton";
import { useMemo } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import { AcquisitionResponse, LayerResponse } from "../../responseTypes";
import { useResetCompare } from "../../utils";

export function useSelectedAcquisition(): {
  selectedAcquisitionIds: string[];
  selectedAcquisitions: AcquisitionResponse[];
  acquisitions?: AcquisitionResponse[];
  isLoading: boolean;
} {
  const { boundaryId } = useParams();
  const [searchParams] = useSearchParams();
  const { data: acquisitions, isLoading } = useUIQuery<AcquisitionResponse[]>(
    ["acquisition", { params: { boundary: boundaryId as string } }],
    true,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );
  const acquisitionParams = JSON.stringify(searchParams.getAll("acquisition"));
  const acquisitionParam = useMemo(
    () => searchParams.getAll("acquisition"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [acquisitionParams],
  );
  let acquisitionIds = acquisitionParam;
  if (acquisitionIds.length === 0) {
    acquisitionIds = acquisitions?.[0] ? [acquisitions[0].id.toString()] : [];
  }
  const acquisitionIdsString = JSON.stringify(acquisitionIds);
  return useMemo(() => {
    const selected =
      acquisitions?.filter(
        (acquisition: AcquisitionResponse) =>
          acquisitionIds.indexOf(acquisition.id.toString()) > -1,
      ) || [];
    return {
      selectedAcquisitionIds: acquisitionIds,
      selectedAcquisitions: selected,
      acquisitions,
      isLoading,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquisitionIdsString, acquisitions, isLoading]);
}

export function AcquisitionsTab() {
  useResetCompare();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedAcquisitionIds, acquisitions } = useSelectedAcquisition();
  return (
    <>
      <Typography component="h5" variant="h6">
        Acquisitions
      </Typography>
      {acquisitions ? (
        <List dense>
          {acquisitions.map((acquisition: AcquisitionResponse) => {
            const labelId = `checkbox-list-label-${acquisition.id}`;
            const selected =
              selectedAcquisitionIds.indexOf(acquisition.id.toString()) > -1;
            return (
              <ListItemButton
                key={acquisition.id}
                role={undefined}
                onClick={() => {
                  const searchAcquisitions = searchParams.getAll("acquisition");
                  if (
                    searchAcquisitions.indexOf(acquisition.id.toString()) > -1
                  ) {
                    searchParams.delete("acquisition");
                    searchAcquisitions.forEach((id: string) => {
                      if (id !== acquisition.id.toString()) {
                        searchParams.append("acquisition", id);
                      }
                    });
                  } else {
                    if (
                      !searchParams.has("acquisition") &&
                      acquisitions[0].id !== acquisition.id
                    ) {
                      searchParams.append(
                        "acquisition",
                        acquisitions[0].id.toString(),
                      );
                    }
                    searchParams.append(
                      "acquisition",
                      acquisition.id.toString(),
                    );
                  }
                  setSearchParams(searchParams);
                }}
                selected={selected}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selected}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={
                    <strong>
                      {acquisition.date_acquired
                        ? new Date(
                            acquisition.date_acquired,
                          ).toLocaleDateString()
                        : "Unknown date"}
                    </strong>
                  }
                  secondary={
                    <>
                      {" "}
                      {acquisition.altitude} ft <br />{" "}
                      {acquisition.layers
                        .map((layer: LayerResponse) => layer.image_type_name)
                        .join(", ")}
                    </>
                  }
                />
              </ListItemButton>
            );
          })}
        </List>
      ) : (
        <Skeleton sx={{ p: 0, mt: -3 }} width="100%" height="180px" />
      )}
    </>
  );
}
