import {
  Button,
  Typography,
  Skeleton,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  DialogTitle,
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  Alert,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  ListItemIcon,
  ListItemButton,
  Chip,
  Autocomplete,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useParams, useSearchParams } from "react-router-dom";
import { useUIMutation, useUIQuery } from "../../queries";
import { ClientTag, Feature, Project } from "../../types";
import { useForm, Controller } from "react-hook-form";
import {
  BoundaryResponse,
  ClientTagResponse,
  FeatureResponse,
  ProjectResponse,
} from "../../responseTypes";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { MapContext } from "../../App";
import { useContext, useEffect, useMemo, useState } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { DRAW_STYLE } from "./AnalysisTab";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";
import {
  getGeometryMidPoint,
  useResetCompare,
  useStableSetSearchParams,
} from "../../utils";
import { Popup } from "../Popup";
import mapboxgl from "mapbox-gl";
import { FeaturePopup } from "../FeaturePopup";
import PlaceIcon from "@mui/icons-material/Place";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";
import { useConfirm } from "material-ui-confirm";

function FeatureModalForm({
  onClose,
  onSubmit,
  error,
  isLoading,
  initial,
  onDelete,
}: {
  onClose: () => void;
  onSubmit: (data: Feature) => void;
  error?: string | null;
  isLoading: boolean;
  initial?: Feature;
  onDelete?: () => void;
}) {
  const { boundaryId } = useParams();
  const { data: boundary } = useUIQuery<BoundaryResponse>(
    ["boundary", { id: boundaryId as string }],
    true,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );
  const confirm = useConfirm();
  const { data: tags } = useUIQuery<ClientTag[]>([
    "clienttag",
    { params: [["client__boundary_set", boundaryId as string]] },
  ]);
  const { mutateAsync: createTag } = useUIMutation<
    { name: string; client: string },
    ClientTagResponse
  >("clienttag", { method: "POST", invalidateQueryKey: ["clienttag"] });
  const { control, handleSubmit, register, watch, reset, resetField } =
    useForm<Feature>({
      defaultValues: initial
        ? {
            ...initial,
            tag_values: undefined,
            tags: initial.tag_values?.map((t) => t?.id),
          }
        : {
            image: null,
            title: "",
            desc: "",
            tags: [],
          },
    });
  const image = watch("image");
  const imageFileName: string = useMemo(() => {
    if (image) {
      if (image?.[0]?.name) {
        return image[0].name;
      } else if (typeof image === "string") {
        // image is a string, get the filename from the url
        const img = (image as string)?.split("/")?.pop();
        if (img) {
          // return the filename without query string
          return img?.split("?")[0];
        }
      }
    }
    return "";
  }, [image]);
  const onFormSubmit = async (data: Feature) => {
    await onSubmit(data);
    if (!initial) {
      reset();
    }
  };
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <DialogContent>
        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField
                autoFocus
                label="Title"
                type="text"
                fullWidth
                {...field}
              />
            </FormControl>
          )}
        />
        <Controller
          name="desc"
          control={control}
          render={({ field }) => (
            <FormControl sx={{ mt: 2 }} fullWidth>
              <TextField
                label="Description"
                type="text"
                multiline
                rows={2}
                fullWidth
                {...field}
              />
            </FormControl>
          )}
        />
        {/* Tag autocomplete field with Controller*/}
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <FormControl sx={{ mt: 2 }} fullWidth>
              <Autocomplete
                multiple
                id="tags-filled"
                options={tags || []}
                openOnFocus
                getOptionLabel={(option) => {
                  // if string, return that, other wise return the name
                  return typeof option === "string" ? option : option.name;
                }}
                value={
                  tags?.filter((tag) =>
                    field.value?.includes(tag.id as number),
                  ) || []
                }
                onBlur={field.onBlur}
                onChange={async (_, value) => {
                  // iterate over value - if any value is a string, then create a new tag, and replace the string with the new tag
                  const newValues = await Promise.all(
                    value.map(async (v) => {
                      if (typeof v === "string") {
                        // is the string in the current list of tags?

                        const existingTag = tags?.find((t) => t.name === v);
                        if (existingTag) {
                          return existingTag.id as number;
                        } else {
                          // create a new tag
                          const newTag = await createTag({
                            name: v,
                            client: (boundary?.client as number).toString(),
                          });
                          return newTag.id;
                        }
                      }
                      return v.id as number;
                    }),
                  );
                  field.onChange(newValues);
                }}
                defaultValue={[] as ClientTag[]}
                freeSolo
                renderTags={(
                  value: readonly ClientTag[],
                  getTagProps: ({ index }: { index: number }) => any,
                ) =>
                  value.map((option: ClientTag | string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={typeof option === "string" ? option : option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Tags"
                    placeholder="Type and press enter to add tags"
                  />
                )}
              />
            </FormControl>
          )}
        />
        {/* file input field with Controller*/}
        <FormControl sx={{ mt: 2, display: "flex" }}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Button variant="contained" component="label">
                Upload Image
                {imageFileName && `: ${imageFileName}`}
                <input
                  aria-labelledby="image-label"
                  hidden
                  accept="image/*"
                  type="file"
                  {...register("image")}
                />
              </Button>
            </Box>
            <Box>
              {image && (
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => {
                    // delete the image from the form
                    resetField("image", {
                      defaultValue: null,
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        {!!onDelete && (
          <Button
            onClick={async () => {
              try {
                await confirm({
                  title: "Delete Feature",
                  description: "Are you sure you want to delete this feature?",
                  confirmationText: "Delete",
                  cancellationText: "Cancel",
                });
                await onDelete();
                onClose();
              } catch (e) {
                // do nothing
              }
            }}
            disabled={isLoading}
            color="error"
          >
            Delete
          </Button>
        )}
        <LoadingButton loading={isLoading} onClick={handleSubmit(onFormSubmit)}>
          {!!onDelete ? "Update" : "Create"}
        </LoadingButton>
      </DialogActions>
    </form>
  );
}
function UpdateFeatureModal({
  feature,
  open,
  onClose,
  onSuccess,
}: {
  feature?: FeatureResponse | null;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: updateFeature, isPending } = useUIMutation<
    Feature & { image?: File | null },
    FeatureResponse
  >(`feature/${feature?.id}`, {
    method: "PATCH",
    invalidateQueryKey: ["feature"],
    fileKeys: ["image"],
  });
  const { mutateAsync: deleteFeature, isPending: isDeletePending } =
    useUIMutation<null, null>(`feature/${feature?.id}`, {
      method: "DELETE",
      invalidateQueryKey: ["feature"],
    });
  const onSubmit = (data: Feature & { image?: File | null }) => {
    const { image, ...rest } = data;
    if (!feature) {
      return;
    }
    if (data.image !== feature.image) {
      (rest as Feature).image = image;
    }
    return updateFeature(
      {
        ...rest,
        feature_geometry: feature.feature_geometry,
        project: feature.project,
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(`Feature: ${response.title} successfully updated`, {
            variant: "success",
          });
          onClose();
          onSuccess();
        },
        onError: (e) => {
          console.log(e);
          setError(
            "Something went wrong saving these details, please try again",
          );
        },
      },
    );
  };
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Update Feature</DialogTitle>
      {feature && (
        <FeatureModalForm
          onSubmit={onSubmit}
          onDelete={async () => {
            await deleteFeature(null);
            enqueueSnackbar(`Feature: ${feature.title} successfully deleted`, {
              variant: "success",
            });
          }}
          onClose={onClose}
          error={error}
          isLoading={isPending || isDeletePending}
          initial={feature}
          key={feature?.id}
        />
      )}
    </Dialog>
  );
}
function CreateFeatureModal({
  geom,
  open,
  onClose,
  onSuccess,
}: {
  geom: GeoJSON.GeoJSON | null;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const [searchParams] = useSearchParams();
  const project = searchParams.get("project");
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: createFeature, isPending } = useUIMutation<
    Feature & { image?: File | null },
    FeatureResponse
  >("feature", {
    method: "POST",
    invalidateQueryKey: ["feature"],
    fileKeys: ["image"],
  });
  const onSubmit = async (data: Feature & { image?: File | null }) => {
    return createFeature(
      {
        ...data,
        image: data.image,
        feature_geometry: (geom as FeatureCollection).features[0].geometry as
          | Polygon
          | MultiPolygon,
        project: parseInt(project as string, 10),
      },
      {
        onSuccess: (response) => {
          // Do a success toast that says "<Feature name> successfully created"
          enqueueSnackbar(`Feature: ${response.title} successfully created`, {
            variant: "success",
          });
          onClose();
          onSuccess();
        },
        onError: (e) => {
          console.log(e);
          setError(
            "Something went wrong saving these details, please try again",
          );
        },
      },
    );
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Feature</DialogTitle>
      <FeatureModalForm
        onSubmit={onSubmit}
        onClose={onClose}
        error={error}
        isLoading={isPending}
      />
    </Dialog>
  );
}
function DrawOverlay({
  setOpenFeatureModalGeom,
  drawnFeature,
  setDrawnFeature,
}: {
  setOpenFeatureModalGeom: (geom: GeoJSON.GeoJSON) => void;
  drawnFeature: GeoJSON.FeatureCollection | null;
  setDrawnFeature: (geom: GeoJSON.FeatureCollection | null) => void;
}) {
  const [searchParams] = useSearchParams();
  const { mapState } = useContext(MapContext);
  const [draw, setDraw] = useState<MapboxDraw | null>(null);
  useEffect(() => {
    if (mapState) {
      // create a new mapbox draw instance
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        styles: DRAW_STYLE,
        controls: {
          point: true,
          polygon: true,
          trash: true,
        },
      });
      // add the draw instance to the map
      mapState.addControl(draw);
      setDraw(draw);
      // remove the draw instance from the map when the component unmounts
      return () => {
        mapState.removeControl(draw);
        setDraw(null);
      };
    }
  }, [mapState]);
  useEffect(() => {
    // when the drawn feature is null, delete all features
    if (!drawnFeature && draw) {
      try {
        draw.deleteAll();
      } catch (e) {
        // do nothing - sometimes happens during unmount
        console.log(e);
      }
    }
  }, [draw, drawnFeature]);

  // effect for listening to draw events
  useEffect(() => {
    if (draw && mapState) {
      const changeEvent = (e: mapboxgl.MapboxEvent) => {
        // get the geojson from the draw instance
        const geojson = draw.getAll();
        // delete all but the latest feature
        draw.delete(geojson.features.map((f) => f.id as string).slice(0, -1));
        // delete all but the latest
        geojson.features = geojson.features.slice(-1);
        if (geojson.features.length > 0) {
          setDrawnFeature(geojson);
        } else {
          setDrawnFeature(null);
        }
      };
      // listen to draw.create, draw.update, and draw.delete events
      mapState.on("draw.create", changeEvent);
      mapState.on("draw.update", changeEvent);
      mapState.on("draw.delete", changeEvent);
      // remove the event listeners when the component unmounts
      return () => {
        mapState.off("draw.create", changeEvent);
        mapState.off("draw.update", changeEvent);
        mapState.off("draw.delete", changeEvent);
      };
    }
  }, [draw, searchParams, mapState, setDrawnFeature]);
  if (drawnFeature) {
    const [lng, lat] = getGeometryMidPoint(drawnFeature);
    return (
      <Popup
        map={mapState}
        element={
          <Button onClick={() => setOpenFeatureModalGeom(drawnFeature)}>
            Create Feature Here
          </Button>
        }
        lat={lat}
        lng={lng}
      />
    );
  }
  return null;
}

function FeaturesList({ projectId }: { projectId: string }) {
  const { mapState } = useContext(MapContext);
  const [searchParams] = useSearchParams();
  const stableSetSearchParams = useStableSetSearchParams();
  const selectedFeatureForEdit = searchParams.get("editFeature");

  // fetch features using useUIQuery
  const { data: features, isLoading } = useUIQuery<FeatureResponse[]>([
    "feature",
    { params: { project: projectId } },
  ]);
  // get the selected feature from the features list
  const selectedFeature = selectedFeatureForEdit
    ? features?.find(
        (f) => f.id === parseInt(selectedFeatureForEdit as string, 10),
      )
    : null;
  const clearSelectedFeatureForEdit = () => {
    searchParams.delete("editFeature");
    stableSetSearchParams(searchParams);
  };
  useEffect(() => {
    // when the features are loaded, add them to the map as a source and layer
    if (mapState) {
      mapState.addSource("features", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
      // add a fill layer for the feature
      mapState.addLayer({
        id: "features-polygons",
        type: "fill",
        source: "features",
        paint: {
          "fill-color": "#088",
          "fill-opacity": 0.4,
          "fill-outline-color": "#ddd",
        },
      });
      mapState.addLayer({
        id: "features-points",
        type: "circle",
        source: "features",
        filter: ["all", ["==", "$type", "Point"]],
        paint: {
          "circle-radius": 10,
          "circle-color": "#1CCC00",
        },
      });
      const onClick = (ev: mapboxgl.MapMouseEvent) => {
        const params = new URLSearchParams(window.location.search);
        // get properties of the feature that was clicked
        const feature = mapState.queryRenderedFeatures(ev.point, {
          layers: ["features-polygons", "features-points"],
        })[0];
        if (feature) {
          // set the feature id in the search params
          params.set("selectedFeature", feature?.properties?.id);
          stableSetSearchParams(params);
        }
      };
      const onMouseEnter = (ev: mapboxgl.MapMouseEvent) => {
        mapState.getCanvas().style.cursor = "pointer";
      };
      const onMouseLeave = (ev: mapboxgl.MapMouseEvent) => {
        mapState.getCanvas().style.cursor = "";
      };
      // listen to click events on the map
      mapState.on("click", ["features-points", "features-polygons"], onClick);
      mapState.on(
        "mouseenter",
        ["features-points", "features-polygons"],
        onMouseEnter,
      );
      mapState.on(
        "mouseleave",
        ["features-points", "features-polygons"],
        onMouseLeave,
      );

      return () => {
        // remove the source and layer when the component unmounts
        mapState.off(
          "click",
          ["features-points", "features-polygons"],
          onClick,
        );
        mapState.off(
          "mouseenter",
          ["features-points", "features-polygons"],
          onMouseEnter,
        );
        mapState.off(
          "mouseleave",
          ["features-points", "features-polygons"],
          onMouseLeave,
        );
        mapState.removeLayer("features-points");
        mapState.removeLayer("features-polygons");
        mapState.removeSource("features");
      };
    }
  }, [mapState, stableSetSearchParams]);
  useEffect(() => {
    // when the features are loaded, add them to the map as a source and layer
    if (mapState && features) {
      (mapState.getSource("features") as mapboxgl.GeoJSONSource)?.setData({
        type: "FeatureCollection",
        features: features.map((f) => ({
          type: "Feature",
          geometry: f.feature_geometry as Polygon,
          properties: {
            id: f.id,
            title: f.title,
            desc: f.desc,
            image: f.image,
          },
        })),
      });
    }
  }, [mapState, features]);

  let list: JSX.Element | null;
  if (isLoading) {
    list = <Skeleton variant="rectangular" height={100} />;
  } else if (features?.length) {
    list = (
      <>
        <Typography variant="h6">Features</Typography>
        <List dense>
          {features?.map((feature, index) => (
            <ListItem disablePadding key={feature.id}>
              <ListItemButton
                onClick={() => {
                  const params = new URLSearchParams(window.location.search);
                  params.set("selectedFeature", feature.id.toString());
                  stableSetSearchParams(params);
                }}
              >
                <ListItemIcon>
                  {feature?.feature_geometry?.type === "Point" ? (
                    <PlaceIcon />
                  ) : (
                    <HexagonOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={`${feature?.title}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </>
    );
  } else {
    list = (
      <Typography>
        No features in this project yet. Draw on the map to get started.
      </Typography>
    );
  }
  return (
    <>
      <Box sx={{ mt: 2 }}>{list}</Box>
      <FeaturePopup />
      <UpdateFeatureModal
        feature={selectedFeature}
        open={!!selectedFeature}
        onSuccess={clearSelectedFeatureForEdit}
        onClose={clearSelectedFeatureForEdit}
        key={selectedFeature?.id}
      />
    </>
  );
}
function CreateProjectDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { boundaryId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  useResetCompare();
  const { mutateAsync: createProject, isPending } = useUIMutation<
    Project,
    ProjectResponse
  >("project", { method: "POST", invalidateQueryKey: ["project"] });
  const { control, handleSubmit } = useForm<Project>({
    defaultValues: {
      name: "",
      status: null,
      summary: "",
    },
  });
  const [error, setError] = useState<string | null>(null);
  const onSubmit = (data: Project) => {
    createProject(
      { ...data, boundary: parseInt(boundaryId as string, 10) },
      {
        onSuccess: (response) => {
          // onSuccess, set the search param for project to the new project id
          // and close the dialog
          searchParams.set("project", response.id.toString());
          setSearchParams(searchParams);
          // Do a success toast that says "<Project name> successfully created"
          enqueueSnackbar(`Project: ${response.name} successfully created`, {
            variant: "success",
          });
          onClose();
        },
        onError: (e) => {
          console.log(e);
          setError(
            "Something went wrong saving these details, please try again",
          );
        },
      },
    );
  };

  // return a Dialog component with a material UI form + react-hook-form Controllers to create a new project with the following fields:
  // name, status and description
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Create Project</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {error && (
            <Alert sx={{ mb: 2 }} severity="error">
              {error}
            </Alert>
          )}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  autoFocus
                  label="Name"
                  type="text"
                  fullWidth
                  {...field}
                />
              </FormControl>
            )}
          />
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <FormControl sx={{ mt: 2 }} fullWidth>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  id="status"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value as Project["status"]);
                  }}
                >
                  <MenuItem value="">Select Status...</MenuItem>
                  <MenuItem value="Created">Created</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="summary"
            control={control}
            render={({ field }) => (
              <FormControl sx={{ mt: 2 }} fullWidth>
                <TextField
                  label="Description"
                  type="text"
                  multiline
                  rows={2}
                  fullWidth
                  {...field}
                />
              </FormControl>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isPending}>
            Cancel
          </Button>
          <LoadingButton loading={isPending} onClick={handleSubmit(onSubmit)}>
            Create
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function ProjectsTab() {
  const { boundaryId } = useParams();
  const { data: projects, isLoading } = useUIQuery<ProjectResponse[]>([
    "project",
    { params: { boundary: boundaryId as string } },
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [drawnFeature, setDrawnFeature] = useState<FeatureCollection | null>(
    null,
  );
  const [openFeatureModalGeom, setOpenFeatureModalGeom] =
    useState<GeoJSON.GeoJSON | null>(null);
  const project = searchParams.get("project");
  return (
    <>
      <Typography component="h5" variant="h6">
        Projects
      </Typography>
      <Button
        sx={{ width: 155, mt: 2, mb: 4 }}
        variant="contained"
        onClick={() => setIsCreateOpen(true)}
        startIcon={<AddIcon />}
      >
        New Project
      </Button>
      <CreateProjectDialog
        open={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
      />
      {/* list out all the projects, if they have loaded, otherwise show a skeleton */}
      {isLoading ? (
        <>
          <Skeleton width="100%" height="56px" />
        </>
      ) : (
        <FormControl fullWidth>
          <InputLabel id={`select-project-label`}>Select a project</InputLabel>
          <Select
            labelId={`select-project-label`}
            id={`select-project`}
            value={project || ""}
            label="Select a project"
            onChange={(event: SelectChangeEvent) => {
              searchParams.set("project", event.target.value);
              setSearchParams(searchParams);
            }}
          >
            <MenuItem value={""}>None</MenuItem>
            {projects?.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {project && (
        <>
          <FeaturesList projectId={project} />
          <DrawOverlay
            drawnFeature={drawnFeature}
            setDrawnFeature={setDrawnFeature}
            setOpenFeatureModalGeom={setOpenFeatureModalGeom}
          />
          <CreateFeatureModal
            onSuccess={() => {
              // clear the drawn feature
              setDrawnFeature(null);
            }}
            open={!!openFeatureModalGeom}
            geom={openFeatureModalGeom}
            onClose={() => setOpenFeatureModalGeom(null)}
          />
        </>
      )}
    </>
  );
}
