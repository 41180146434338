import { Button, ButtonProps } from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export function LinkButton(
  props: ButtonProps<typeof RouterLink, RouterLinkProps>,
) {
  return <Button component={RouterLink} {...props} />;
}
