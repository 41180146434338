import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, FormControl } from "@mui/material";
import { useUIQuery } from "../queries";
import { VectorResponse } from "../responseTypes";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Geometries } from "../types";
import { MapContext } from "../App";
import { getGeometryMidPoint } from "../utils";

export default function VectorSearchDialog({
  isOpen,
  onClose,
  vector,
}: {
  isOpen: boolean;
  onClose: () => void;
  vector: VectorResponse;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { mapState } = useContext(MapContext);
  const [selectedTree, setSelectedTree] = useState<{
    label: string;
    id: number;
    geom: any;
  } | null>(null);
  useEffect(() => {
    setSelectedTree(null);
  }, [isOpen]);
  const [inputValue, setInputValue] = useState("");

  const { data, isFetching } = useUIQuery<Geometries[]>(
    [
      "geometries/idlookup",
      {
        params: {
          additional_data_id: inputValue,
          vector: vector.id.toString() || "",
        },
      },
    ],
    !!(inputValue && vector.id),
  );
  const options: { label: string; id: number; geom: any }[] =
    data?.map((x) => ({
      geom: x.geom,
      label: x.additional_data[vector?.vector_id_column as string].toString(),
      id: x.gid as number,
    })) || [];
  if (selectedTree && !options.find((o) => o.id === selectedTree?.id)) {
    options.push(selectedTree);
  }
  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle>ID Search</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Search for a particular feature by it's ID
        </DialogContentText>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Autocomplete
            id={`asynchronous-id`}
            filterOptions={(x) => x}
            getOptionLabel={(option) => `${option.label}`}
            options={options}
            noOptionsText={"Enter an ID"}
            autoComplete
            loading={isFetching}
            filterSelectedOptions
            value={selectedTree}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              setSelectedTree(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={"ID"} variant="outlined" />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            if (selectedTree) {
              searchParams.set("selectedVectorLayer", vector.id.toString());
              searchParams.set("selectedGid", selectedTree.id.toString());
              setSearchParams(searchParams);
              mapState?.setZoom(15);
              mapState?.setCenter(getGeometryMidPoint(selectedTree.geom));
            }
            onClose();
          }}
        >
          Show
        </Button>
      </DialogActions>
    </Dialog>
  );
}
