import {
  Typography,
  Box,
  Stack,
  TextField,
  Divider,
  Grid,
  IconButton,
  Card,
  Button,
  Chip,
  Paper,
  Skeleton,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useSelectedAcquisition } from "./AcquisitionsTab";
import { ColorMapSlider } from "../ColorMapSlider";
import { Fragment, useContext, useMemo, useState } from "react";
import { vegStrataColors, defaultVegStrataToggles } from "../../utils";
import {
  AcquisitionResponse,
  LayerResponse,
  WithRequired,
} from "../../responseTypes";
import FilterDialog from "../FilterDialog";
import SearchIcon from "@mui/icons-material/Search";
import VectorSearchDialog from "../VectorSearchDialog";
import { DrawerWidthContext } from "../../App";
import { FeatureFlagged, useFeatureFlags } from "../FeatureFlag";
import InputAdornment from "@mui/material/InputAdornment";

function VegStrataToggles({
  overlay,
  compare,
}: {
  overlay: WithRequired<LayerResponse, "veg_strata">;
  compare?: boolean;
}) {
  const filterKey = compare ? "col" : "ol";
  const [searchParams, setSearchParams] = useSearchParams({
    [`${filterKey}${overlay.id}vs`]: defaultVegStrataToggles.join("_"),
  });
  const vegStrataToggles =
    searchParams
      .get(`${filterKey}${overlay.id}vs`)
      ?.split("_")
      ?.map((x) => parseInt(x, 10)) || defaultVegStrataToggles.slice();
  const colours = overlay.veg_strata_colour
    ? overlay.veg_strata_colour
    : vegStrataColors;
  return (
    <>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {Object.entries(overlay.veg_strata).map(([key, label], i) => {
          if (i === 0) {
            // can't toggle on the no veg layer
            return null;
          }
          const checked = vegStrataToggles[i] === 1;
          const onChange = (
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean,
          ) => {
            vegStrataToggles[i] = checked ? 1 : 0;
            searchParams.set(
              `${filterKey}${overlay.id}vs`,
              vegStrataToggles.join("_"),
            );
            setSearchParams(searchParams);
          };
          return (
            <Grid key={key} item xs={6}>
              <Card
                sx={{
                  border: `solid 4px ${checked ? colours[i] : "white"}`,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    sx={{ mr: 0, fontSize: 0.5, p: 0.5 }}
                    control={<Switch checked={checked} onChange={onChange} />}
                    label={label as string}
                  />
                </FormGroup>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
function ValidateOnBlurInputField({
  value,
  setValue,
  min,
  max,
  label,
  endAdornment,
}: {
  value: number;
  setValue: (value: number) => void;
  min: number;
  max: number;
  label: string;
  endAdornment?: React.ReactNode;
}) {
  const [uncommittedValue, setUncommittedValue] = useState<string | null>(null);
  return (
    <TextField
      aria-label={label}
      InputProps={{
        endAdornment: endAdornment,
      }}
      inputProps={{
        style: {
          height: 20,
          width: 40,
          ...(endAdornment
            ? {
                paddingTop: "4px",
                paddingBottom: "4px",
              }
            : { padding: "4px 8px" }),
        },
      }}
      onBlur={(e) => {
        setUncommittedValue(null);
        const v = parseFloat(e.target.value);
        if (isNaN(v)) {
          return;
        }
        if (v < min) {
          setValue(min);
        } else if (v > max) {
          setValue(max);
        } else {
          setValue(v);
        }
      }}
      onChange={(e) => {
        const v = parseFloat(e.target.value);
        if (isNaN(v) || v < min || v > max || v.toString() !== e.target.value) {
          setUncommittedValue(e.target.value);
        } else {
          setUncommittedValue(null);
          setValue(v);
        }
      }}
      value={uncommittedValue !== null ? uncommittedValue : value}
      type="tel"
    />
  );
}
function OverlayColorSlider({
  overlay,
  compare,
}: {
  overlay: LayerResponse;
  compare?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterKey = compare ? "col" : "ol";
  const minValue = searchParams.get(`${filterKey}cmap${overlay.id}min`);
  const maxValue = searchParams.get(`${filterKey}cmap${overlay.id}max`);
  const showUnder =
    searchParams.get(`${filterKey}cmap${overlay.id}start`) !== "off";
  const showOver =
    searchParams.get(`${filterKey}cmap${overlay.id}end`) !== "off";
  const minValueFloat = minValue ? parseFloat(minValue) : overlay.lower;
  const maxValueFloat = maxValue ? parseFloat(maxValue) : overlay.upper;
  const value: [number, number] = useMemo(() => {
    return [minValueFloat, maxValueFloat];
  }, [minValueFloat, maxValueFloat]);
  return (
    <>
      <FormControl key={overlay.id + "slider"} fullWidth>
        <ColorMapSlider
          showUnder={showUnder}
          showOver={showOver}
          imageType={overlay.image_type}
          min={overlay.lower}
          max={overlay.upper}
          value={value}
          onChange={(newValue: [number, number]) => {
            searchParams.set(
              `${filterKey}cmap${overlay.id}min`,
              newValue[0].toString(),
            );
            searchParams.set(
              `${filterKey}cmap${overlay.id}max`,
              newValue[1].toString(),
            );
            setSearchParams(searchParams, { replace: true });
          }}
        />
      </FormControl>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row">
          <FormControl>
            <Switch
              size="small"
              sx={{ ml: -1 }}
              aria-label="Hide values lower than range"
              checked={
                searchParams.get(`${filterKey}cmap${overlay.id}start`) !== "off"
              }
              onChange={(event, checked) => {
                searchParams.set(
                  `${filterKey}cmap${overlay.id}start`,
                  checked ? "on" : "off",
                );
                setSearchParams(searchParams);
              }}
            />
          </FormControl>
          <FormControl>
            <ValidateOnBlurInputField
              label="Lower bound of colour scaling"
              value={value[0]}
              min={overlay.lower}
              max={value[1]}
              endAdornment={
                overlay.image_type === "THERMAL" && (
                  <InputAdornment position="end">°C</InputAdornment>
                )
              }
              setValue={(v: number) => {
                searchParams.set(
                  `${filterKey}cmap${overlay.id}min`,
                  v.toString(),
                );
                setSearchParams(searchParams, {
                  replace: true,
                });
              }}
            />
          </FormControl>
        </Stack>
        <Stack direction="row">
          <FormControl>
            <ValidateOnBlurInputField
              label="Upper bound of colour scaling"
              value={value[1]}
              min={value[0]}
              max={overlay.upper}
              endAdornment={
                overlay.image_type === "THERMAL" && (
                  <InputAdornment position="end">°C</InputAdornment>
                )
              }
              setValue={(v: number) => {
                searchParams.set(
                  `${filterKey}cmap${overlay.id}max`,
                  v.toString(),
                );
                setSearchParams(searchParams, {
                  replace: true,
                });
              }}
            />
          </FormControl>
          <FormControl>
            <Switch
              size="small"
              aria-label="Hide values higher than range"
              sx={{ mr: -1 }}
              checked={
                searchParams.get(`${filterKey}cmap${overlay.id}end`) !== "off"
              }
              onChange={(event, checked) => {
                searchParams.set(
                  `${filterKey}cmap${overlay.id}end`,
                  checked ? "on" : "off",
                );
                setSearchParams(searchParams);
              }}
            />
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
}

function VectorFilterButton({
  layer,
  compare,
}: {
  layer: LayerResponse;
  compare?: boolean;
}) {
  const keyPrefix = compare ? "compare_filter" : "filter";
  const [searchParams, setSearchParams] = useSearchParams();
  const isComparisonOn = searchParams.get("compare") === "on";
  const firstFilterableVector = layer.vectors.find(
    (v) => !!(v.vector_id_column || v.filter_fields),
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const filters = useMemo(() => {
    const filterSet: Set<string> = new Set();
    searchParams.forEach((value, key) => {
      if (key.startsWith(`${keyPrefix}_${firstFilterableVector?.id}_`)) {
        const filterKey = key.replace(
          `${keyPrefix}_${firstFilterableVector?.id}_`,
          "",
        );
        filterSet.add(filterKey);
      }
    });
    return Array.from(filterSet).sort();
  }, [searchParams, firstFilterableVector?.id, keyPrefix]);
  return (
    <>
      {firstFilterableVector && !isComparisonOn ? (
        <Paper sx={{ p: 1, mt: 1 }}>
          <Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {firstFilterableVector?.filter_fields && (
                <Button sx={{ flex: 1 }} onClick={() => setIsFilterOpen(true)}>
                  {filters.length === 0 ? "Filter features" : "Change filters"}
                </Button>
              )}
              {!isComparisonOn && firstFilterableVector?.vector_id_column && (
                <IconButton
                  size="large"
                  aria-label="ID search"
                  onClick={() => {
                    setIsSearchOpen(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </Box>
            {firstFilterableVector?.filter_fields && (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {filters.map((filter) => {
                  return (
                    <Chip
                      sx={{ mt: 1 }}
                      key={filter}
                      onDelete={() => {
                        searchParams.delete(
                          `${keyPrefix}_${layer.id}_${filter}`,
                        );
                        setSearchParams(searchParams);
                      }}
                      label={`${filter} (${
                        searchParams.getAll(
                          `${keyPrefix}_${layer.id}_${filter}`,
                        ).length
                      })`}
                    />
                  );
                })}
              </Box>
            )}
          </Stack>
        </Paper>
      ) : null}
      {firstFilterableVector && (
        <FilterDialog
          isOpen={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          compare={compare}
          vector={firstFilterableVector as LayerResponse}
        />
      )}
      {!isComparisonOn && firstFilterableVector && (
        <VectorSearchDialog
          isOpen={isSearchOpen}
          onClose={() => setIsSearchOpen(false)}
          vector={firstFilterableVector}
        />
      )}
    </>
  );
}
function OverlaySelect({
  selectedAcquisitions,
  index,
  overlay,
  overlays,
  currentlySelectedOverlays,
  compare,
}: {
  selectedAcquisitions: AcquisitionResponse[];
  index: number;
  overlay?: LayerResponse;
  overlays: string[];
  currentlySelectedOverlays: string[];
  compare?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { flagObject } = useFeatureFlags([]);

  const idPrefix = `overlay${compare ? "-compare" : ""}-layer-${index}`;
  const overlayKey = compare ? "compareOverlay" : "overlay";
  const overlayModiferKey = compare ? "col" : "ol";
  return (
    <Fragment key={overlay?.id}>
      <FormControl sx={{ mt: 4 }} fullWidth>
        <InputLabel id={`${idPrefix}-select-label`}>
          Overlay {index + 1}
        </InputLabel>
        <Select
          labelId={`${idPrefix}-select-label`}
          id={`${idPrefix}-select`}
          value={overlay?.id?.toString() || ""}
          label={`Overlay ${index + 1}`}
          onChange={(event: SelectChangeEvent) => {
            overlays.forEach((ol, i) => {
              if (i === 0) {
                if (i === index) {
                  if (event.target.value) {
                    searchParams.set(overlayKey, event.target.value);
                  } else {
                    searchParams.delete(overlayKey);
                    searchParams.delete(`${overlayModiferKey}cmap${ol}min`);
                    searchParams.delete(`${overlayModiferKey}cmap${ol}max`);
                    searchParams.delete(`${overlayModiferKey}cmap${ol}start`);
                    searchParams.delete(`${overlayModiferKey}cmap${ol}end`);
                    searchParams.delete(`${overlayModiferKey}${ol}vs`);
                  }
                } else if (ol) {
                  searchParams.set(overlayKey, ol);
                } else {
                  searchParams.delete(overlayKey);
                }
              } else if (i === index) {
                if (event.target.value) {
                  searchParams.append(overlayKey, event.target.value);
                } else {
                  searchParams.delete(`${overlayModiferKey}cmap${ol}min`);
                  searchParams.delete(`${overlayModiferKey}cmap${ol}max`);
                  searchParams.delete(`${overlayModiferKey}cmap${ol}start`);
                  searchParams.delete(`${overlayModiferKey}cmap${ol}end`);
                  searchParams.delete(`${overlayModiferKey}${ol}vs`);
                }
              } else if (i !== index && ol) {
                searchParams.append(overlayKey, ol);
              }
            });
            setSearchParams(searchParams);
          }}
        >
          <MenuItem value={""}>None</MenuItem>
          {selectedAcquisitions?.map((acquisition: AcquisitionResponse) => {
            return acquisition?.layers?.map((layer) => {
              if (
                layer.overlay &&
                (overlay?.id === layer.id ||
                  currentlySelectedOverlays.indexOf(layer.id.toString()) ===
                    -1) &&
                flagObject?.[`layer.view.${layer.image_type.toLowerCase()}`]
              ) {
                return <MenuItem value={layer.id}>{layer.name}</MenuItem>;
              }
              return null;
            });
          })}
        </Select>
      </FormControl>
      {overlay?.overlay &&
        !overlay?.veg_strata &&
        overlay.image_type !== "BUILDING_FOOTPRINT" &&
        overlay.image_type !== "VECTOR" &&
        overlay.image_type !== "CROWN_POLYGONS" && (
          <FeatureFlagged flag="colour.sliders">
            <OverlayColorSlider compare={compare} overlay={overlay} />
          </FeatureFlagged>
        )}
      {overlay?.overlay && overlay.veg_strata ? (
        <FeatureFlagged flag="vegstrata.toggles">
          <VegStrataToggles
            compare={compare}
            overlay={overlay as WithRequired<LayerResponse, "veg_strata">}
          />
        </FeatureFlagged>
      ) : null}
      {overlay?.overlay && overlay.image_type === "VECTOR" ? (
        <FeatureFlagged flag="vector.filter">
          <VectorFilterButton layer={overlay} compare={compare} />
        </FeatureFlagged>
      ) : null}
    </Fragment>
  );
}

export function getOverlayIds(
  searchParams: URLSearchParams,
  key: string,
  numberOfOverlays: number,
): { overlays: string[]; currentlySelectedOverlays: string[] } {
  const overlays = searchParams.getAll(key);
  const currentlySelectedOverlays = overlays.slice();
  // always one more than the number of overlays
  // unless all overlays are already selected
  if (overlays.length < numberOfOverlays) {
    overlays.push("");
  }
  return { overlays, currentlySelectedOverlays };
}

function ImagerySelect({
  selectedAcquisitions,
  compare,
  overlayIdToLayer,
  numberOfOverlays,
  isLoading,
}: {
  selectedAcquisitions: AcquisitionResponse[];
  overlayIdToLayer: Record<string, LayerResponse>;
  numberOfOverlays: number;
  compare?: boolean;
  isLoading: boolean;
}) {
  const satelliteKey = compare ? "compareSatellite" : "satellite";
  const baselayerKey = compare ? "compareBaselayer" : "baselayer";
  const [searchParams, setSearchParams] = useSearchParams({
    [satelliteKey]: "on",
  });
  const isComparisonOn = searchParams.get("compare") === "on";
  const { overlays, currentlySelectedOverlays } = getOverlayIds(
    searchParams,
    compare ? "compareOverlay" : "overlay",
    numberOfOverlays,
  );
  let heading = `Select ArborCam Data`;
  if (isComparisonOn) {
    if (compare) {
      heading = `Right Side Data`;
    } else {
      heading = `Left Side Data`;
    }
  }

  return (
    <>
      <Typography
        sx={{
          mt: 2,
          mr: 3,
          ml: 3,
        }}
        variant="h6"
      >
        {heading}
      </Typography>
      <FormGroup
        sx={{
          mt: 2,
          mr: 3,
          ml: 3,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={searchParams.get(satelliteKey) === "on"}
              onChange={(event, checked) => {
                searchParams.set(satelliteKey, checked ? "on" : "off");
                setSearchParams(searchParams);
              }}
            />
          }
          label="Satellite, streets and places"
        />
      </FormGroup>
      <Box
        sx={{
          mr: 3,
          ml: 3,
        }}
      >
        <Box
          sx={{
            mt: 2,
          }}
        >
          <FormControl fullWidth>
            {isLoading ? (
              <Skeleton height={56} variant="rectangular" />
            ) : (
              <>
                <InputLabel
                  id={`base-layer-select-label${compare ? "-compare" : ""}`}
                >
                  Base Layer
                </InputLabel>
                <Select
                  labelId={`base-layer-select-label${
                    compare ? "-compare" : ""
                  }`}
                  id={`base-layer-select${compare ? "-compare" : ""}`}
                  value={searchParams.get(baselayerKey) || ""}
                  label="Base Layer"
                  onChange={(event: SelectChangeEvent) => {
                    searchParams.set(baselayerKey, event.target.value);
                    setSearchParams(searchParams);
                  }}
                >
                  <MenuItem value={""}>None</MenuItem>
                  {selectedAcquisitions?.map((acquisition) => {
                    return acquisition?.layers?.map((layer) => {
                      if (!layer.overlay) {
                        return (
                          <MenuItem value={layer.id}>{layer.name}</MenuItem>
                        );
                      }
                      return null;
                    });
                  })}
                </Select>
              </>
            )}
          </FormControl>
        </Box>
        <Box
          sx={{
            mt: 0,
            mb: 3,
            width: "100%",
          }}
        >
          {overlays.map((overlayId, index) => {
            const overlay = overlayIdToLayer[overlayId];
            return (
              <Fragment key={overlayId}>
                <Divider sx={{ p: 2 }} />
                <OverlaySelect
                  selectedAcquisitions={selectedAcquisitions}
                  index={index}
                  overlay={overlay}
                  overlays={overlays}
                  currentlySelectedOverlays={currentlySelectedOverlays}
                  compare={compare}
                />
              </Fragment>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
export function useOverlays(selectedAcquisitions: AcquisitionResponse[]) {
  const { flagObject } = useFeatureFlags([]);

  const returned = useMemo(() => {
    const layerIdToLayer: Record<string, LayerResponse> = {};
    let numberOfOverlays = 0;
    selectedAcquisitions?.forEach((acquisition) => {
      acquisition.layers.forEach((layer) => {
        layerIdToLayer[layer.id.toString()] = layer;
        if (
          layer.overlay &&
          flagObject?.[`layer.view.${layer.image_type.toLowerCase()}`]
        ) {
          numberOfOverlays++;
        }
      });
    });
    return [layerIdToLayer, numberOfOverlays];
  }, [selectedAcquisitions, flagObject]);
  return returned as [Record<string, LayerResponse>, number];
}
export const SIDE_CONTAINER_SIZE = 314;
export function ImageryTab() {
  const { compareWidthSet } = useContext(DrawerWidthContext);
  const [searchParams, setSearchParams] = useSearchParams({
    satellite: "on",
  });
  const { selectedAcquisitions, isLoading } = useSelectedAcquisition();
  const [overlayIdToLayer, numberOfOverlays] =
    useOverlays(selectedAcquisitions);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: 265,
          mr: 3,
          ml: 3,
        }}
      >
        <Typography component="h5" variant="h6">
          Data
        </Typography>
        <FeatureFlagged flag="compare.imagery">
          <FormControlLabel
            sx={{ mr: 0 }}
            control={
              <Switch
                checked={searchParams.get("compare") === "on"}
                onChange={(event, checked) => {
                  searchParams.set("compare", checked ? "on" : "off");
                  setSearchParams(searchParams);
                }}
              />
            }
            label="Compare"
          />
        </FeatureFlagged>
      </Box>
      <Divider
        sx={{
          p: 1,
          mr: 3,
          ml: 3,
        }}
      />
      {!compareWidthSet && (
        <ImagerySelect
          selectedAcquisitions={selectedAcquisitions}
          overlayIdToLayer={overlayIdToLayer}
          numberOfOverlays={numberOfOverlays}
          isLoading={isLoading}
        />
      )}
      {compareWidthSet && (
        <Box
          sx={{
            display: "flex",
            flex: "1 0 auto",
          }}
        >
          <Box
            sx={{
              flex: "1 0 auto",
              width: SIDE_CONTAINER_SIZE - 49,
            }}
          >
            <ImagerySelect
              selectedAcquisitions={selectedAcquisitions}
              overlayIdToLayer={overlayIdToLayer}
              numberOfOverlays={numberOfOverlays}
              isLoading={isLoading}
            />
          </Box>
          <Divider
            flexItem
            orientation="vertical"
            sx={{ ml: 1, mr: 1, flex: "0 0 auto" }}
          />
          <Box
            sx={{
              flex: "1 0 auto",
              width: SIDE_CONTAINER_SIZE - 49,
            }}
          >
            <ImagerySelect
              compare
              selectedAcquisitions={selectedAcquisitions}
              overlayIdToLayer={overlayIdToLayer}
              numberOfOverlays={numberOfOverlays}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
