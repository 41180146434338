import Slider from "@mui/material/Slider";
import { useCallback, useState } from "react";
import { calcColormapStyleJson } from "../utils";
import { LayerResponse } from "../responseTypes";

const leftSideTooltip = {
  "& .MuiSlider-valueLabel": {
    left: "-5px",
    "&:before": {
      left: "18px",
    },
  },
};
const rightSideTooltip = {
  "& .MuiSlider-valueLabel": {
    right: "-5px",
    "&:before": {
      left: "unset",
      right: "10px",
    },
  },
};
export function ColorMapSlider({
  imageType,
  min,
  max,
  value,
  onChange,
  showUnder,
  showOver,
}: {
  imageType: LayerResponse["image_type"];
  min: number;
  max: number;
  value: [number, number];
  onChange: (value: [number, number]) => void;
  showUnder: boolean;
  showOver: boolean;
}) {
  const valueLabelFormat = useCallback(
    (value: number) => {
      if (imageType === "THERMAL") {
        return `${value} °C`;
      } else {
        return value;
      }
    },
    [imageType],
  );
  // when null, use the query param value
  // while sliding, we don't want the noise of updating the params
  const [uncommitedValue, setUncommitedValue] = useState<
    [number, number] | null
  >(null);
  const displayedValue = uncommitedValue || value;
  const colormap = calcColormapStyleJson(
    imageType,
    displayedValue[0],
    displayedValue[1],
    displayedValue[0],
    displayedValue[1],
  );
  const firstColor = showUnder ? colormap[0][1] : "#bfbfbf80";
  const lastColor = showOver ? colormap[colormap.length - 1][1] : "#bfbfbf80";
  const minPercent = (displayedValue[0] - min) / (max - min);
  const maxPercent = (displayedValue[1] - min) / (max - min);
  const selectedMin = displayedValue[0];
  const selectedMax = displayedValue[1];
  const minStyle =
    selectedMin > max - (max - min) / 2 ? rightSideTooltip : leftSideTooltip;
  const maxStyle =
    selectedMax < max - (max - min) / 2 ? leftSideTooltip : rightSideTooltip;
  const selectedRange = selectedMax - selectedMin;
  const trackGradient = colormap
    .map(
      ([level, color]: [number, string]) =>
        `${color} ${((level - selectedMin) / selectedRange) * 100}%`,
    )
    .join(", ");
  const gradient = `linear-gradient(to right, ${firstColor} ${
    minPercent * 100
  }%, #bfbfbf80, ${lastColor} ${maxPercent * 100}%)`;
  const handleChange = (event: Event, newValue: number | number[]) => {
    setUncommitedValue(newValue as [number, number]);
  };

  return (
    <Slider
      size="medium"
      value={displayedValue}
      step={0.1}
      onChange={handleChange}
      onChangeCommitted={(_, newValue) => {
        onChange(newValue as [number, number]);
        setUncommitedValue(null);
      }}
      onMouseDown={() => {
        setUncommitedValue(value);
      }}
      getAriaLabel={(index: number) => {
        return `${index === 0 ? "Lower" : "Upper"} bound of colour scaling`;
      }}
      valueLabelDisplay="auto"
      valueLabelFormat={valueLabelFormat}
      min={min}
      disableSwap
      max={max}
      sx={{
        height: 12,
        "& .MuiSlider-track": {
          opacity: 1,
          background: `linear-gradient(to right, ${trackGradient})`,
        },
        "& .MuiSlider-rail": {
          opacity: 1,
          background: gradient,
        },
        "& .MuiSlider-thumb:last-of-type": {
          backgroundColor: colormap[colormap.length - 1][1],
        },
        "& .MuiSlider-thumb": {
          height: 25,
          width: 25,
          backgroundColor: colormap[0][1],
        },
        '& [data-index="0"]': {
          ...minStyle,
        },
        '& [data-index="1"]': {
          ...maxStyle,
        },
      }}
    />
  );
}
