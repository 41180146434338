import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useFeatureFlags } from "./FeatureFlag";
import { useState } from "react";
import { MarkdownContent, useContent } from "./MarkdownContent";

function setAgreedInLocalStorage() {
  // set the local storage key "agreed" to the current time
  // so we know when the user agreed to the terms
  localStorage.setItem("agreed", Date.now().toString());
}

function shouldShowTerms() {
  const agreed = localStorage.getItem("agreed");
  // if the user has not agreed to the terms in the past day, show the terms
  if (!agreed) {
    return true;
  }
  const agreedDate = new Date(parseInt(agreed, 10));
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  return now.getTime() - agreedDate.getTime() > oneDay;
}
export function TermsModal() {
  const {
    flagList: [isAgreeToTermsEnabled],
  } = useFeatureFlags(["agree.to.terms.modal"]);
  const agreementText = useContent()?.["terms.and.conditions.agreement"];
  const [open, setOpen] = useState(shouldShowTerms());
  if (!agreementText) {
    return null;
  }
  return (
    <Dialog
      maxWidth="lg"
      // change backdrop to more opaque
      slotProps={{
        backdrop: { style: { backgroundColor: "rgba(0,0,0,0.95)" } },
      }}
      open={isAgreeToTermsEnabled && open}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">Terms and Conditions</DialogTitle>
      <Box
        sx={{
          mt: 2,
          ml: 2,
          mr: 2,
        }}
      >
        <Typography variant="body1" component="p">
          {agreementText}
        </Typography>
      </Box>
      <DialogContent
        sx={{
          m: 2,
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.60) inset",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            p: 2,
          }}
        >
          <MarkdownContent contentKey="terms.and.conditions" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setAgreedInLocalStorage();
            setOpen(false);
          }}
        >
          Agree and proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}
