import { Boundary, Layer } from "./types";
import { extent } from "geojson-bounds";
import { useCallback, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

export interface BoundaryBounds {
  min: [number, number];
  max: [number, number];
}

export const defaultVegStrataToggles: (0 | 1)[] = [0, 1, 1, 1, 1, 1, 1, 1, 1];

export const vegStrataColors = [
  "#ffffff",
  "#7dafff",
  "#00ff11",
  "#f6ff00",
  "#ff7700",
  "#ff0000",
  "#7700ff",
  "#ff00c3",
  "#00e5ff",
];

var thermalColors = [
  "#0006bd",
  "#005aec",
  "#37afff",
  "#76ffbd",
  "#0dc900",
  "#fbff06",
  "#d9b003",
  "#ff5f74",
  "#ff0000",
];

export const vciColors = [
  "#ff0000",
  "#e39104",
  "#fbff06",
  "#0dc900",
  "#00fbff",
  "#005aec",
  "#9a1ba5",
  "#fb00ff",
  "#cf03e9",
];

export const quantities = [0.001, 1, 2, 3, 4, 5, 6, 7, 7.999];
function opacityToHex(opacity: number) {
  return Math.round(opacity * 255)
    .toString(16)
    .padStart(2, "0");
}

export function calcColormapStyleJson(
  image_type: Layer["image_type"],
  lower: number,
  upper: number,
  min: number,
  max: number,
  lop = 1,
  hop = 1,
): [number, string][] {
  let colors = thermalColors;
  if (image_type === "VCI") {
    // use VCI colours
    colors = vciColors;
  }
  const colormap: [number, string][] = [];
  min = Math.min(min, lower);
  colormap.push([min, colors[0] + opacityToHex(lop)]);
  colormap.push([lower, colors[0] + opacityToHex(lop)]);
  var quantityIncrement = (upper - lower) / 8;
  for (var i = 0; i < 9; i++) {
    colormap.push([
      lower + quantityIncrement * quantities[i],
      colors[i] + opacityToHex(1),
    ]);
  }
  colormap.push([upper, colors[8] + opacityToHex(hop)]);
  max = Math.max(max, upper);
  colormap.push([max, colors[8] + opacityToHex(hop)]);
  return colormap;
}

export function calcColormapStyle(
  image_type: Layer["image_type"],
  lower: number | null,
  upper: number | null,
  min: number,
  max: number,
  lop = 1,
  hop = 1,
) {
  if (lower === null || upper === null) {
    return "";
  }
  return encodeURIComponent(
    JSON.stringify(
      calcColormapStyleJson(image_type, lower, upper, min, max, lop, hop),
    ),
  );
}

export function createVegStrataColormap(
  vegStrataColour: string[],
  vegStrataToggles: (0 | 1)[],
) {
  var colormap = [[[0, 1], vegStrataColour[0] + "00"]];
  for (var i = 1; i < vegStrataColour.length; i++) {
    colormap.push([
      [i, i + 1],
      vegStrataColour[i] + (vegStrataToggles[i] ? "FF" : "00"),
    ]);
  }
  return encodeURIComponent(JSON.stringify(colormap));
}
function getMidPoint(bounds: BoundaryBounds): [number, number] {
  return [
    (bounds.min[0] + bounds.max[0]) / 2,
    (bounds.min[1] + bounds.max[1]) / 2,
  ];
}
export function getGeometryMidPoint(geometry: GeoJSON.GeoJSON) {
  const [west, south, east, north] = extent(geometry);
  return getMidPoint({
    min: [west, north],
    max: [east, south],
  });
}
function getFloatParam(param: string, searchParams: URLSearchParams) {
  return searchParams.get(param)
    ? parseFloat(searchParams.get(param) || "")
    : null;
}
export function getLatLngFromSearchParamsOrBoundary(
  boundary: Boundary | undefined,
  searchParams: URLSearchParams,
) {
  let lat = getFloatParam("lat", searchParams);
  let lng = getFloatParam("lng", searchParams);
  if (boundary && (lat === null || lng === null)) {
    const bounds = getBounds([boundary]);
    if (bounds) {
      [lat, lng] = getMidPoint(bounds);
    }
  }
  return [lat, lng];
}
function getBoundsFromGeom(
  geoms: (GeoJSON.Geometry | GeoJSON.GeometryCollection)[],
): BoundaryBounds {
  return geoms.reduce(
    (
      acc: BoundaryBounds,
      curr: GeoJSON.Geometry | GeoJSON.GeometryCollection,
    ) => {
      if (!curr) {
        return acc;
      }
      const [west, south, east, north] = extent(curr);
      acc.min = [Math.min(acc.min[0], west), Math.min(acc.min[1], north)];
      acc.max = [Math.max(acc.max[0], east), Math.max(acc.max[1], south)];
      return acc;
    },
    { min: [180, 90], max: [-180, -90] },
  );
}
export function getBounds(
  boundaries: Boundary[] | undefined,
): BoundaryBounds | null {
  if (boundaries) {
    return getBoundsFromGeom(
      boundaries.reduce((acc: GeoJSON.MultiPolygon[], boundary) => {
        if (boundary.geom) {
          acc.push(boundary.geom);
        }
        return acc;
      }, []),
    );
  }
  return null;
}

// https://usehooks.com/usePrevious/
export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function getTopLayer(map: mapboxgl.Map) {
  // the top layer should be undefined unless the mapbox draw layers have already been added - in which case the top layer is gl-draw-polygon-fill-active.cold
  const topLayer = map
    .getStyle()
    ?.layers?.find((layer) => layer.id === "gl-draw-line.cold");
  return topLayer;
}

export function useResetCompare() {
  const [searchParams, setSearchParams] = useSearchParams();
  // on mount, ensure that the compare url param is set to off
  useEffect(() => {
    searchParams.set("compare", "off");
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useStableSetSearchParams() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  // setSearchParams changes every time the URL changes
  // We don't want that to happen, it only matters for relative links
  // which we aren't using, so make it stable here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(setSearchParams, []);
}
