import { useAuth0 } from "@auth0/auth0-react";
import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { FeatureCollection } from "geojson";
import { useCallback } from "react";
import { LayerResponse } from "./responseTypes";
import * as env from "./env";
export function useAccessToken() {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const { data } = useQuery({
    queryKey: ["accessToken", user?.id],
    queryFn: () => getAccessTokenSilently(),
    enabled: isAuthenticated,
    staleTime: 1000 * 60,
  });
  if (!isAuthenticated) {
    return undefined;
  }
  return data;
}

async function fetchBounds(
  queryKey: string[],
  token: string | undefined,
): Promise<{ bounds: [number, number, number, number] } | undefined> {
  if (!token || queryKey.length < 2 || !queryKey[1]) {
    return;
  }
  const response = await fetch(
    `${env.actilesProto}://${env.actilesProto === "http" ? "" : "a."}${
      env.actilesCogDomain
    }/cog/bounds?url=${queryKey[1]}&token=${token}`,
  );
  if (!response.ok) {
    throw new Error("Network response was not ok!");
  }
  return response.json();
}

async function fetchStats(
  layer: LayerResponse,
  geojson: FeatureCollection | null,
  token: string | undefined,
  params?: string | Record<string, string> | URLSearchParams | string[][],
): Promise<{ response: FeatureCollection; layer: LayerResponse } | undefined> {
  if (!geojson || !token || !layer?.s3_path) {
    return;
  }
  let url = `${env.actilesProto}://${env.actilesProto === "http" ? "" : "a."}${
    env.actilesCogDomain
  }/cog/statistics?url=${layer.s3_path}&nodata=255&max_size=2048`;
  // add params to URL
  if (params) {
    url += `&${new URLSearchParams(params).toString()}`;
  }
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(geojson),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error("Network response was not ok!");
  }
  const respJson = (await response.json()) as FeatureCollection;
  return {
    response: respJson,
    layer,
  };
}

export function useStatistics(
  layers: LayerResponse[],
  geojson: FeatureCollection | null,
) {
  const token = useAccessToken();
  const queryFn = useCallback(
    async ({
      queryKey,
    }: {
      queryKey: [
        url: string,
        options: {
          geojson: FeatureCollection | null;
          layers: LayerResponse[];
        },
      ];
    }) => {
      return Promise.all(
        queryKey[1].layers.map((layer) =>
          fetchStats(
            layer,
            queryKey[1].geojson,
            token,
            layer.veg_strata
              ? Object.keys(layer.veg_strata)
                  .map((key) => ["c", key])
                  .concat([["categorical", "true"]])
              : undefined,
          ),
        ),
      );
    },
    [token],
  );
  return useQuery<
    ({ response: FeatureCollection; layer: LayerResponse } | undefined)[],
    unknown,
    ({ response: FeatureCollection; layer: LayerResponse } | undefined)[],
    [
      url: string,
      options: {
        geojson: FeatureCollection | null;
        layers: LayerResponse[];
      },
    ]
  >({
    queryKey: ["statistics", { geojson, layers }],
    queryFn,
    enabled: !!token && !!layers?.length && !!geojson,
    staleTime: 1000 * 60 * 60 * 24,
  });
}

export function useRasterBounds(s3Paths: string[]) {
  const token = useAccessToken();
  const queryFn = useCallback(
    async ({ queryKey }: { queryKey: string[] }) =>
      Promise.all(
        queryKey
          .slice(1)
          .map((s3Path) => fetchBounds([queryKey[0], s3Path], token)),
      ),
    [token],
  );
  const enabled = !!token;
  return useQuery({
    queryKey: ["boundaryJson"].concat(s3Paths),
    queryFn,
    enabled,
    staleTime: 1000 * 60 * 60 * 24,
  });
}

export function useUIQuery<T>(
  queryKeys: [
    key: string,
    options?: {
      id?: string | number;
      urlSuffix?: string;
      params?:
        | string
        | Record<string, string>
        | URLSearchParams
        | string[][]
        | undefined;
      extraKey?: string;
    },
  ],
  enabled: boolean = true,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  options?: Omit<UseQueryOptions<T>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  },
  mustBeAuthenticated: boolean = true,
) {
  const token = useAccessToken();
  return useQuery<T>({
    queryKey: queryKeys.concat([queryKeys?.[1]?.extraKey || ""]),
    queryFn: async () => {
      const [key, options] = queryKeys;
      let url = env.djangoUrl || "";
      if (options?.id) {
        url += `/api/${key}/${options.id}/`;
      } else {
        url += `/api/${key}/`;
      }
      if (options?.urlSuffix) {
        url += `${options.urlSuffix}/`;
      }
      if (options?.params) {
        url += `?${new URLSearchParams(options.params).toString()}`;
      }
      const response = await fetch(url, {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok!");
      }

      return response.json();
    },
    enabled: (!!token || !mustBeAuthenticated) && enabled,
    ...options,
  });
}

export function useUIMutation<T extends object | null, R = unknown>(
  endpoint: string,
  options?: {
    method: "POST" | "PUT" | "PATCH" | "DELETE";
    invalidateQueryKey?: QueryKey;
    exactInvalidation?: boolean;
    fileKeys?: string[];
  },
) {
  const token = useAccessToken();
  // get the queryClient from the context
  const queryClient = useQueryClient();
  return useMutation<R, unknown, T>({
    mutationFn: async (data: T) => {
      let response: Response;
      if (options?.fileKeys) {
        const dataWithFile = data as any;

        // if we have a file, we need to use FormData
        // and send as multipart/form-data
        // All form data except the file is json encoded under the key "data"
        const formData = new FormData();
        // iterate through all fields, add all fields to a "data" obj unless it is a file type
        // in which case it should be added to formData separately
        let dataObj: any = {};
        let files: [string, File][] = [];
        Object.keys(dataWithFile).forEach((key) => {
          if (options.fileKeys?.includes(key) && dataWithFile[key]) {
            // iterate over FileList dataWithFile[key] and add to files
            // however if it's null, keep it in the JSON
            for (let i = 0; i < dataWithFile[key]?.length || 0; i++) {
              files.push([key, dataWithFile[key][i]]);
            }
          } else {
            dataObj[key] = dataWithFile[key];
          }
        });
        formData.append("data", JSON.stringify(dataObj));
        files.forEach(([key, file]) => {
          formData.append(key, file);
        });

        response = await fetch(`${env.djangoUrl}/api/${endpoint}/`, {
          method: options?.method || "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
      } else {
        response = await fetch(`${env.djangoUrl}/api/${endpoint}/`, {
          method: options?.method || "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      }
      if (!response.ok) {
        throw new Error("Network response was not ok!");
      }
      if (response.status === 204) {
        return null;
      } else {
        return response.json();
      }
    },
    // when successful the queryClient should invalidate the query so that it is refetched
    onSuccess: () => {
      if (options?.invalidateQueryKey) {
        queryClient.invalidateQueries({
          queryKey: options?.invalidateQueryKey,
          exact: !!options?.exactInvalidation,
        });
      }
    },
  });
}

const styleUrl = `https://api.mapbox.com/styles/v1/${env.mapboxStyle}?access_token=${env.mapboxToken}`;
function fetchStyle() {
  return fetch(styleUrl).then((res) => res.json());
}
export const useStyle = () =>
  useQuery<mapboxgl.Style>({
    queryKey: ["style"],
    queryFn: fetchStyle,
    // expire one per week
    staleTime: 1000 * 60 * 60 * 24 * 7,
  });
