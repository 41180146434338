import { useSearchParams } from "react-router-dom";
import { useUIQuery } from "../queries";
import { getGeometryMidPoint, useStableSetSearchParams } from "../utils";
import {
  Box,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { VectorResponse } from "../responseTypes";
import { Popup } from "../components/Popup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Geometry } from "geojson";
import { Geometries } from "../types";
function Gallery({ images }: { images: string[] }) {
  return (
    <ImageList cols={2} sx={{ pl: 1, pr: 1, pb: 1 }}>
      {images.map((image: string, index: number) => (
        <ImageListItem key={index}>
          <a
            href={image}
            title={"Open in new window"}
            rel="noreferrer"
            target="_blank"
          >
            <img src={image} style={{ maxWidth: "100%" }} alt="" />
          </a>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

function VectorPopupInner({
  attributes,
  properties,
  photoAttribute,
}: {
  attributes: { key: string; label: string }[];
  photoAttribute: string | null;
  properties: mapboxgl.MapboxGeoJSONFeature["properties"];
}) {
  if (!properties) {
    return <span>No properties</span>;
  }
  return (
    <Box
      sx={{
        maxHeight: 350,
        overflowY: "auto",
        overflowWrap: "anywhere",
        mt: 1,
      }}
    >
      <List dense>
        {attributes.map(({ key, label }) => {
          // don't display empty values, or the photo attribute as we've migrated those to a separate photo and will be under the .images key
          if (
            key === photoAttribute ||
            properties?.[key] === undefined ||
            properties?.[key] === "" ||
            properties?.[key] === null
          ) {
            return null;
          }
          return (
            <ListItem dense sx={{ pt: 0, pb: 0 }} key={key}>
              <ListItemText
                primary={
                  <>
                    <strong>{label}:</strong> {properties?.[key]}
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>
      {properties?.images && properties.images.length > 0 && (
        <>
          <Typography sx={{ pl: 1 }}>
            <strong>Photos:</strong>
          </Typography>
          <Gallery images={properties?.images} />
        </>
      )}
    </Box>
  );
}

export function VectorPopup({ map }: { map: mapboxgl.Map | null }) {
  const [searchParams] = useSearchParams();
  const stableSetSearchParams = useStableSetSearchParams();

  const selectedVectorLayer = searchParams.get("selectedVectorLayer");
  const selectedGid = searchParams.get("selectedGid");
  const { data: geom } = useUIQuery<Geometries>(
    ["geometries", { id: selectedGid as string }],
    !!selectedGid,
  );

  const [lat, setLat] = useState<null | string>(null);
  const [lng, setLng] = useState<null | string>(null);

  const { data: vector } = useUIQuery<VectorResponse>(
    ["vector", { id: selectedVectorLayer as string }],
    !!selectedVectorLayer,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );
  const onClose = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    params.delete("selectedGid");
    params.delete("selectedVectorLayer");
    stableSetSearchParams(params);
  }, [stableSetSearchParams]);
  const geomProperties = useMemo(() => {
    if (!geom) {
      return null;
    }
    return {
      ...geom?.additional_data,
      ...geom,
    };
  }, [geom]);

  useEffect(() => {
    if (!selectedGid) {
      setLat(null);
      setLng(null);
    } else if (
      vector?.attributes &&
      selectedGid &&
      selectedVectorLayer &&
      map
    ) {
      // try to load from the mapbox tile, but fall back onto the geom if it's not there
      const feature = map.queryRenderedFeatures(undefined, {
        filter: ["==", ["get", "gid"], parseInt(selectedGid, 10)],
      })?.[0];
      let geometry: Geometry;
      if (feature) {
        geometry = feature.geometry;
      } else if (geom) {
        geometry = (geom as Geometries).geom;
      } else {
        return;
      }
      const [lng, lat] = getGeometryMidPoint(geometry);
      setLat(lat.toFixed(7));
      setLng(lng.toFixed(7));
    }
  }, [vector, selectedGid, selectedVectorLayer, map, geom]);

  if (
    vector?.attributes &&
    selectedGid &&
    selectedVectorLayer &&
    map &&
    lat !== null &&
    lng !== null
  ) {
    // try to load from the mapbox tile, but fall back onto the geom if it's not there
    const feature = map.queryRenderedFeatures(undefined, {
      filter: ["==", ["get", "gid"], parseInt(selectedGid, 10)],
    })?.[0];
    return (
      <Popup
        map={map}
        element={
          <VectorPopupInner
            photoAttribute={vector?.photo_id_column || null}
            attributes={vector?.attributes}
            properties={geomProperties || feature?.properties}
          />
        }
        closable
        onClose={onClose}
        lat={parseFloat(lat)}
        lng={parseFloat(lng)}
        waitForFlyTo
        flyTo
      />
    );
  } else {
    return null;
  }
}
