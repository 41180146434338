import mapboxgl from "mapbox-gl";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

export function Popup({
  map,
  lat,
  lng,
  element,
  closable,
  onClose,
  waitForFlyTo,
  flyTo,
}: {
  map: mapboxgl.Map | null;
  lat: number | null;
  lng: number | null;
  closable?: boolean;
  waitForFlyTo?: boolean;
  flyTo?: boolean;
  onClose?: () => void;
  element: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) {
  const [popup, setPopup] = useState<mapboxgl.Popup | null>(null);
  const hasElement = element !== null;
  useEffect(() => {
    if (map && lat !== null && lng !== null && hasElement) {
      const popup = new mapboxgl.Popup({
        closeOnClick: false,
        closeButton: closable,
        className: "hidden",
      });
      if (closable && onClose) {
        popup.on("close", onClose);
      }
      popup.setLngLat([lng, lat]).setHTML("").addTo(map);
      // we want to avoid the popup location from being changed after render, so don't render until the map finishes panning
      const onMoveEnd = () => {
        setPopup(popup);
      };
      if (waitForFlyTo && flyTo) {
        map.once("moveend", onMoveEnd);
      } else {
        onMoveEnd();
      }
      if (flyTo) {
        map.flyTo({ center: [lng, lat] });
      }
      return () => {
        if (closable) {
          popup?.off("close", onClose);
        }
        if (map) {
          map.off("moveend", onMoveEnd);
        }
        popup?.remove();
        setPopup(null);
      };
    }
  }, [map, lat, lng, hasElement, onClose, closable, flyTo, waitForFlyTo]);
  useEffect(() => {
    if (popup) {
      popup.removeClassName("hidden");
    }
  }, [popup]);
  if (popup) {
    const el = popup
      .getElement()
      ?.getElementsByClassName("mapboxgl-popup-content");
    if (el) {
      return ReactDOM.createPortal(element, el[0]);
    }
  }
  return null;
}
