import { useSearchParams } from "react-router-dom";
import { useUIQuery } from "../queries";
import { getGeometryMidPoint, useStableSetSearchParams } from "../utils";
import { Box, Button, Typography } from "@mui/material";
import { FeatureResponse } from "../responseTypes";
import { Popup } from "../components/Popup";
import { useCallback, useContext, useMemo } from "react";
import { MapContext } from "../App";

function FeaturePopupInner({ feature }: { feature: FeatureResponse }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const onEdit = useCallback(() => {
    searchParams.set("editFeature", feature.id.toString());
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams, feature.id]);

  return (
    <Box
      sx={{
        maxHeight: 350,
        overflowY: "auto",
        overflowWrap: "anywhere",
        mt: 1,
      }}
    >
      <Typography variant="h6" sx={{ mb: 1 }}>
        {feature.title}
      </Typography>
      <Typography>{feature.desc}</Typography>
      {feature.image && (
        <Box sx={{ mt: 1 }}>
          <a
            href={feature.image}
            title={"Open in new window"}
            rel="noreferrer"
            target="_blank"
          >
            <img src={feature.image} style={{ maxWidth: "100%" }} alt="" />
          </a>
        </Box>
      )}
      <Box sx={{ textAlign: "center" }}>
        <Button onClick={onEdit}>Edit</Button>
      </Box>
    </Box>
  );
}

export function FeaturePopup() {
  const [searchParams] = useSearchParams();
  const stableSetSearchParams = useStableSetSearchParams();
  const { mapState } = useContext(MapContext);
  const project = searchParams.get("project");
  // fetch features using useUIQuery
  // note: this is a list so that it uses the cached query
  const { data: features } = useUIQuery<FeatureResponse[]>(
    ["feature", { params: project ? { project } : {} }],
    !!project,
  );
  // get selectedFeature from the search params
  const selectedFeature = searchParams.get("selectedFeature");
  // get the feature from the features list
  const feature = features?.find(
    (feature) => feature.id.toString() === selectedFeature,
  );

  // get the lat and lng midpoint from the feature_geometry if polygon, otherwise if point, use the coordinates
  const [lng, lat] = useMemo(() => {
    if (!feature) {
      return [null, null];
    }
    if (
      feature.feature_geometry.type === "Polygon" ||
      feature.feature_geometry.type === "MultiPolygon"
    ) {
      return getGeometryMidPoint(feature.feature_geometry);
    }
    return feature.feature_geometry.coordinates;
  }, [feature]);
  // onClose listener - clears the selectedFeature from the search params
  const onClose = useCallback(() => {
    // get search params from the url
    const windowSearchParams = new URLSearchParams(window.location.search);
    // remove the selectedFeature from the search params
    windowSearchParams.delete("selectedFeature");
    // set the search params
    stableSetSearchParams(windowSearchParams);
  }, [stableSetSearchParams]);
  // if selected feature doesn't exist, return null
  if (!selectedFeature || !feature) {
    return null;
  }
  return (
    <Popup
      map={mapState}
      element={<FeaturePopupInner feature={feature} />}
      closable
      onClose={onClose}
      lat={lat}
      lng={lng}
      waitForFlyTo
      flyTo
    />
  );
}
