import Box from "@mui/material/Box";
import { MapContext, MenuOpenContext } from "../App";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useAccessToken,
  useRasterBounds,
  useStyle,
  useUIQuery,
} from "../queries";
import {
  calcColormapStyle,
  createVegStrataColormap,
  getBounds,
  getGeometryMidPoint,
  getTopLayer,
  useStableSetSearchParams,
} from "../utils";
import { Tab, Tabs } from "@mui/material";
import {
  AcquisitionsTab,
  useSelectedAcquisition,
} from "../components/tabs/AcquisitionsTab";
import { BoundaryTab } from "../components/tabs/BoundaryTab";
import { ImageryTab } from "../components/tabs/ImageryTab";
import { ProjectsTab } from "../components/tabs/ProjectsTab";
import { DownloadTab } from "../components/tabs/DownloadTab";
import { AnalysisTab } from "../components/tabs/AnalysisTab";
import mapboxgl from "mapbox-gl";
import { vegStrataColors, defaultVegStrataToggles } from "../utils";
import { AcquisitionResponse, BoundaryResponse } from "../responseTypes";
import { Vector } from "../types";
import { LayerResponse } from "../responseTypes";
import { VectorPopup } from "../components/VectorPopup";
import { SideMenu } from "../components/SideMenu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LinkButton } from "../components/LinkButton";
import { FeatureFlagged, useFeatureFlags } from "../components/FeatureFlag";
import * as env from "../env";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
  sx?: React.ComponentProps<typeof Box>["sx"];
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        flex: 1,
        display: value === index ? "flex" : "none",
        minWidth: 0,
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            mt: 3,
            mb: 3,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            minWidth: 0,
            ...(sx || {}),
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function makeLayerVisible(layerIds: string[], map: mapboxgl.Map) {
  layerIds.forEach((layerId) => {
    map.setLayoutProperty(layerId, "visibility", "visible");
  });
}
function makeLayerInvisible(layerIds: string[], map: mapboxgl.Map) {
  layerIds.forEach((layerId) => {
    map.setLayoutProperty(layerId, "visibility", "none");
  });
}
function moveAllLayersBefore(
  layerIds: string[],
  previousLayerIds: string[],
  map: mapboxgl.Map,
  layersToNextLayer: Record<string, string>,
) {
  map.moveLayer(layerIds[layerIds.length - 1], previousLayerIds[0]);
  layersToNextLayer[layerIds[layerIds.length - 1]] = previousLayerIds[0];

  layerIds.slice(0, layerIds.length - 1).forEach((layerId, index) => {
    map.moveLayer(layerId, layerIds[index + 1]);
    layersToNextLayer[layerId] = layerIds[index + 1];
  });
}
export default function VerticalTabs({
  boundary,
}: {
  boundary: BoundaryResponse | undefined;
}) {
  const [
    isBoundaryEnabled,
    isAcquisitionsEnabled,
    isImageryEnabled,
    isDownloadEnabled,
    isProjectsEnabled,
    isAnalysisEnabled,
  ] = useFeatureFlags([
    "boundary.tab.view",
    "acquisitions.tab.view",
    "imagery.tab.view",
    "download.tab.view",
    "projects.tab.view",
    "analysis.tab.view",
  ]).flagList;

  const defaultTab = isBoundaryEnabled
    ? "boundary"
    : isAcquisitionsEnabled
      ? "acquisitions"
      : isImageryEnabled
        ? "imagery"
        : isDownloadEnabled
          ? "download"
          : isProjectsEnabled
            ? "projects"
            : isAnalysisEnabled
              ? "analysis"
              : "";
  const [searchParams, setSearchParams] = useSearchParams({
    tab: defaultTab,
  });
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      searchParams.set("tab", newValue.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );
  const backLink = useMemo(() => {
    if (boundary) {
      const [lng, lat] = boundary.geom
        ? getGeometryMidPoint(boundary.geom)
        : [0, 0];
      return `/boundary/popup/${boundary.id}?lat=${lat}&lng=${lng}`;
    }
    return "/";
  }, [boundary]);

  const value = searchParams.get("tab") || defaultTab;

  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        bgcolor: "background.paper",
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "0 0 auto",
          borderRight: 1,
          width: "135px",
          borderColor: "divider",
          marginTop: "16px",
          mb: 1,
        }}
      >
        <FeatureFlagged flag="back.button">
          <LinkButton
            sx={{ pr: 3 }}
            to={backLink}
            aria-label="Back to boundary select"
            title="Back to boundary select"
          >
            <ArrowBackIcon sx={{ mr: 1 }} /> Back
          </LinkButton>
        </FeatureFlagged>

        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{}}
        >
          {isBoundaryEnabled && (
            <Tab value={"boundary"} label="Boundary" {...a11yProps(0)} />
          )}
          {isAcquisitionsEnabled && (
            <Tab
              value={"acquisitions"}
              label="Acquisitions"
              {...a11yProps(1)}
            />
          )}
          {isImageryEnabled && (
            <Tab value={"imagery"} label={"Data"} {...a11yProps(2)} />
          )}
          {isDownloadEnabled && (
            <Tab value={"download"} label="Download" {...a11yProps(3)} />
          )}
          {isProjectsEnabled && (
            <Tab value={"projects"} label="Projects" {...a11yProps(4)} />
          )}
          {isAnalysisEnabled && (
            <Tab value={"analysis"} label="Analysis" {...a11yProps(5)} />
          )}
        </Tabs>
      </Box>
      <Box sx={{ overflow: "auto", flex: 1, display: "flex", minWidth: 0 }}>
        <FeatureFlagged flag="boundary.tab.view">
          <TabPanel sx={{ m: 3 }} value={value} index={"boundary"}>
            <BoundaryTab />
          </TabPanel>
        </FeatureFlagged>
        <FeatureFlagged flag="acquisitions.tab.view">
          <TabPanel sx={{ m: 3 }} value={value} index={"acquisitions"}>
            <AcquisitionsTab />
          </TabPanel>
        </FeatureFlagged>
        <FeatureFlagged flag="imagery.tab.view">
          <TabPanel value={value} index={"imagery"}>
            <ImageryTab />
          </TabPanel>
        </FeatureFlagged>
        <FeatureFlagged flag="download.tab.view">
          <TabPanel sx={{ m: 3 }} value={value} index={"download"}>
            <DownloadTab />
          </TabPanel>
        </FeatureFlagged>
        <FeatureFlagged flag="projects.tab.view">
          <TabPanel sx={{ m: 3 }} value={value} index={"projects"}>
            <ProjectsTab />
          </TabPanel>
        </FeatureFlagged>
        <FeatureFlagged flag="analysis.tab.view">
          <TabPanel sx={{ m: 3 }} value={value} index={"analysis"}>
            <AnalysisTab />
          </TabPanel>
        </FeatureFlagged>
      </Box>
    </Box>
  );
}
function getColorMap(
  raster: LayerResponse,
  lowerOverride: number | null = null,
  upperOverride: number | null = null,
  lowOpacity: number = 1,
  highOpacity: number = 1,
  vegStrataToggles: (0 | 1)[] = defaultVegStrataToggles,
) {
  const { overlay, veg_strata_colour, veg_strata, image_type, lower, upper } =
    raster;
  let colormap_type = "explicit";
  let colormap_list = [];
  let colormap = "";
  if (overlay && image_type !== "CROWN_POLYGONS") {
    colormap_list = veg_strata_colour ? veg_strata_colour : vegStrataColors;
    if (veg_strata) {
      colormap = createVegStrataColormap(colormap_list, vegStrataToggles);
    } else {
      colormap_type = "linear";
      colormap = calcColormapStyle(
        image_type,
        lowerOverride || lower,
        upperOverride || upper,
        lower,
        upper,
        lowOpacity,
        highOpacity,
      );
    }
  }
  return { colormap, colormap_type };
}

function replaceColorMapInUrls(
  source: mapboxgl.RasterSource,
  raster: LayerResponse,
  lowerOverride: number | null = null,
  upperOverride: number | null = null,
  lowOpacity: number = 1,
  highOpacity: number = 1,
  vegStrataToggles: (0 | 1)[] = defaultVegStrataToggles,
): mapboxgl.RasterSource {
  const { colormap, colormap_type } = getColorMap(
    raster,
    lowerOverride,
    upperOverride,
    lowOpacity,
    highOpacity,
    vegStrataToggles,
  );
  return {
    type: source.type,
    tileSize: source.tileSize,
    bounds: source.bounds,
    tiles: source?.tiles?.map((tile) => {
      return tile
        .replace(/colormap=[^&]+/, `colormap=${colormap}`)
        .replace(/colormap_type=[^&]+/, `colormap_type=${colormap_type}`);
    }),
  };
}
interface LayerData {
  layerIds: string[];
  sourceId: string;
  id: number;
  layer: LayerResponse;
  vector?: Vector;
  listeners: Record<string, Function | Function[]>;
}
function getRasterSourceObject(
  bounds: [number, number, number, number],
  raster: LayerResponse,
  token: string,
  lowerOverride: number | null = null,
  upperOverride: number | null = null,
  lowOpacity: number = 1,
  highOpacity: number = 1,
  vegStrataToggles: (0 | 1)[] = defaultVegStrataToggles,
): mapboxgl.RasterSource {
  const { colormap, colormap_type } = getColorMap(
    raster,
    lowerOverride,
    upperOverride,
    lowOpacity,
    highOpacity,
    vegStrataToggles,
  );
  const { s3_path } = raster;

  return {
    type: "raster",
    tiles: [
      `${env.actilesProto}://${env.actilesProto === "http" ? "" : "a."}${
        env.actilesCogDomain
      }/cog/tiles/WebMercatorQuad/{z}/{x}/{y}.webp?url=${s3_path}&token=${token}&colormap=${colormap}&colormap_type=${colormap_type}`,
      `${env.actilesProto}://${env.actilesProto === "http" ? "" : "b."}${
        env.actilesCogDomain
      }/cog/tiles/WebMercatorQuad/{z}/{x}/{y}.webp?url=${s3_path}&token=${token}&colormap=${colormap}&colormap_type=${colormap_type}`,
      `${env.actilesProto}://${env.actilesProto === "http" ? "" : "c."}${
        env.actilesCogDomain
      }/cog/tiles/WebMercatorQuad/{z}/{x}/{y}.webp?url=${s3_path}&token=${token}&colormap=${colormap}&colormap_type=${colormap_type}`,
    ],
    tileSize: 256,
    bounds: bounds,
  };
}
function getVectorSourceObject(
  vector: Vector,
  client_id: number,
  token: string,
): mapboxgl.VectorSource {
  const maxzoom = !vector.maxzoom || vector.maxzoom > 19 ? 19 : vector.maxzoom;
  return {
    type: "vector",
    minzoom: vector.minzoom || 0,
    maxzoom,
    tiles: [
      `${env.actilesProto}://${env.actilesMvtDomain}/vector/tiles/{z}/{x}/{y}?client=${client_id}&token=${token}&vector=${vector?.id}`,
    ],
  };
}

function replaceFiltersInUrls(
  source: mapboxgl.VectorSource,
  raster: LayerResponse,
  filters: Record<string, string[]>,
): mapboxgl.VectorSource {
  return {
    type: source.type,
    tiles: source?.tiles?.map((tile) => {
      const searchParams = new URLSearchParams(tile.split("?")[1]);
      searchParams.forEach((value, key) => {
        if (key.match(/_(lt|gt|lte|gte|like|ilike|isnull|eq|ne|hasvalue)/)) {
          searchParams.delete(key);
        }
      });
      const keys = Object.keys(filters);
      if (keys.length > 0) {
        keys.forEach((key) => {
          filters[key].forEach((value) => {
            searchParams.append(`${key}_eq`, value);
          });
        });
      }
      const params = searchParams.toString();
      return `${tile.split("?")[0]}?${params}`;
    }),
  };
}
function useMoveSatelliteLayer() {
  const { data: style } = useStyle();
  const [bottomLayers, topLayers] = useMemo(() => {
    if (!style?.layers) return [];
    return style?.layers?.reduce(
      (acc, layer) => {
        if (layer.id === "satellite") {
          acc[0].push(layer.id);
        } else if (layer.id !== "background") {
          acc[1].push(layer.id);
        }
        return acc;
      },
      [[], []] as string[][],
    );
  }, [style?.layers]);
  return useCallback(
    (map: mapboxgl.Map, before: string) => {
      bottomLayers.forEach((layerId) => {
        map.moveLayer(layerId, before);
      });
      topLayers.forEach((layerId) => {
        map.moveLayer(layerId);
      });
    },
    [topLayers, bottomLayers],
  );
}
function useImagery(map: mapboxgl.Map | null, compare?: boolean) {
  const moveSatelliteLayer = useMoveSatelliteLayer();
  const { selectedAcquisitions } = useSelectedAcquisition();
  const [searchParams] = useSearchParams();
  const { boundaryId } = useParams();
  let overlayKey: string = "overlay";
  let baselayerKey: string = "baselayer";
  let overlayModiferKey = "ol";
  let filterKeyPrefix = "filter";
  if (compare) {
    overlayKey = "compareOverlay";
    baselayerKey = "compareBaselayer";
    overlayModiferKey = "col";
    filterKeyPrefix = "compare_filter";
  }
  const overlays = searchParams.getAll(overlayKey);
  const baselayer = searchParams.get(baselayerKey);
  const { s3Paths, rasters, vectors } = useMemo(() => {
    return (
      selectedAcquisitions?.reduce(
        (
          acc: {
            s3Paths: string[];
            rasters: LayerResponse[];
            vectors: LayerResponse[];
          },
          { layers }: AcquisitionResponse,
        ) => {
          const [nonVectorLayers, vectorLayers] = layers.reduce(
            (acc: [LayerResponse[], LayerResponse[]], layer) => {
              if (layer.image_type === "VECTOR") {
                acc[1].push(layer);
              } else {
                acc[0].push(layer);
              }
              return acc;
            },
            [[], []] as [LayerResponse[], LayerResponse[]],
          );
          acc.s3Paths = acc.s3Paths.concat(
            nonVectorLayers.map(({ s3_path }) => s3_path as string),
          );
          acc.rasters = acc.rasters.concat(nonVectorLayers);
          acc.vectors = acc.vectors.concat(vectorLayers);
          return acc;
        },
        {
          s3Paths: [],
          rasters: [],
          vectors: [],
        },
      ) || {
        s3Paths: [],
        rasters: [],
        vectors: [],
      }
    );
  }, [selectedAcquisitions]);

  const { data: rasterBounds } = useRasterBounds(s3Paths);
  const token = useAccessToken();
  const [layerDataArray, setLayerDataArray] = useState<LayerData[]>([]);
  const stableSetSearchParams = useStableSetSearchParams();
  useEffect(() => {
    if (map && rasterBounds && rasterBounds?.length === rasters?.length) {
      let firstId = "";
      const topLayer = getTopLayer(map)?.id;
      const layerAndSourceIds: LayerData[] = rasterBounds.reduce(
        (
          layerData: LayerData[],
          rasterBound: { bounds: [number, number, number, number] } | undefined,
          index: number,
        ) => {
          const raster = rasters[index];
          const { id } = raster;
          if (rasterBound) {
            const sourceId = `raster-source-${id}`;
            const layerId = `raster-layer-${id}`;
            if (index === 0) {
              firstId = layerId;
            }
            map.addSource(
              sourceId,
              getRasterSourceObject(
                rasterBound.bounds,
                raster,
                token as string,
              ),
            );
            const before = index === 0 ? topLayer : firstId;
            map?.addLayer(
              {
                id: layerId,
                type: "raster",
                source: sourceId,
                minzoom: 2,
                maxzoom: 24,
                layout: {
                  visibility: "none",
                },
              },
              before,
            );
            layerData.push({
              layerIds: [layerId],
              sourceId,
              id,
              layer: raster,
              listeners: {},
            });
          }
          return layerData;
        },
        [],
      );
      // iterate over the vector array and add vector source and layers to map
      vectors.forEach((layer: LayerResponse) => {
        const { vectors: layerVectors } = layer;
        layerVectors.forEach((vector) => {
          if (!layer.client_id) {
            return;
          }
          const { id } = vector;
          if (!id) {
            return;
          }
          const sourceId = `vector-source-${id}`;
          const layerId = `vector-layer-${id}`;
          map.addSource(
            sourceId,
            getVectorSourceObject(vector, layer.client_id, token as string),
          );
          const onMouseEnter = (ev: mapboxgl.MapMouseEvent) => {
            map.getCanvas().style.cursor = "pointer";
          };
          const onMouseLeave = (ev: mapboxgl.MapMouseEvent) => {
            map.getCanvas().style.cursor = "";
          };
          const [layerIds, onClicks] = vector?.mapbox_styles.reduce(
            (
              layersAndClickListers: [
                string[],
                ((ev: mapboxgl.MapMouseEvent) => void)[],
              ],
              style: any,
              index: number,
            ) => {
              const lid = `${layerId}-${index}`;
              map.addLayer(
                {
                  ...style,
                  maxzoom:
                    !vector.maxzoom || vector.maxzoom > 24
                      ? 24
                      : vector.maxzoom,
                  minzoom: vector.minzoom ?? 0,
                  id: lid,
                  source: sourceId,
                  "source-layer": "default",
                } as mapboxgl.AnyLayer,
                topLayer,
              );
              if (vector.show_popup || vector.zoom_on_click) {
                map.on(`mouseenter`, lid, onMouseEnter);
                map.on(`mouseleave`, lid, onMouseLeave);
              }
              if (vector.zoom_on_click) {
                const onClick = (ev: mapboxgl.MapMouseEvent) => {
                  // zoom to where you clicked
                  const { lng, lat } = ev.lngLat;
                  map.flyTo({
                    center: [lng, lat],
                    zoom: vector.maxzoom ?? 15,
                    essential: false,
                  });
                };
                map.on(`click`, lid, onClick);
                layersAndClickListers[1].push(onClick);
              } else if (vector.show_popup) {
                const onClick = (ev: mapboxgl.MapMouseEvent) => {
                  const params = new URLSearchParams(window.location.search);
                  params.set("selectedVectorLayer", vector.id.toString());
                  const id = map.queryRenderedFeatures(ev.point, {
                    layers: [lid],
                  })?.[0]?.properties?.gid;
                  params.set("selectedGid", id);
                  stableSetSearchParams(params);
                };
                map.on(`click`, lid, onClick);
                layersAndClickListers[1].push(onClick);
              }

              layersAndClickListers[0].push(lid);
              return layersAndClickListers;
            },
            [[], []],
          );
          layerAndSourceIds.push({
            layerIds,
            sourceId,
            id: layer.id,
            vector,
            layer,
            listeners:
              onClicks.length > 0
                ? { onMouseEnter, onMouseLeave, onClicks }
                : {},
          });
        });
      });
      setLayerDataArray(layerAndSourceIds);
      return () => {
        if (map) {
          try {
            setLayerDataArray([]);
            layerAndSourceIds.forEach(
              ({ layerIds, sourceId, listeners }, index) => {
                if (sourceId && layerIds) {
                  layerIds.forEach((layerId) => {
                    // turn off each listener if it exists
                    if (listeners.onClicks) {
                      map.off(
                        "click",
                        layerId,
                        (listeners.onClicks as ((e: any) => void)[])[index],
                      );
                    }
                    if (listeners.onMouseEnter) {
                      map.off(
                        "mouseenter",
                        layerId,
                        listeners.onMouseEnter as (e: any) => void,
                      );
                    }
                    if (listeners.onMouseLeave) {
                      map.off(
                        "mouseleave",
                        layerId,
                        listeners.onMouseLeave as (e: any) => void,
                      );
                    }
                    map.removeLayer(layerId);
                  });
                  map?.removeSource(sourceId);
                }
              },
            );
          } catch (e) {
            console.log(
              "Error removing boundary layers - this is normal if the map is unmounted",
            );
            console.log(e);
          }
        }
      };
    }
  }, [map, rasterBounds, rasters, token, vectors, stableSetSearchParams]);

  useEffect(() => {
    if (map && layerDataArray && layerDataArray.length > 0) {
      const topLayer = getTopLayer(map)?.id;
      try {
        const overlayIdToLayerIds = layerDataArray.reduce(
          (acc: Record<string, string[]>, { layerIds, id }) => {
            if (!acc[id.toString()]) {
              acc[id.toString()] = [...layerIds];
            } else {
              acc[id.toString()] = acc[id.toString()].concat(layerIds);
            }
            return acc;
          },
          {},
        );
        const layersToNextLayer: Record<string, string> = {};
        if (overlays.length > 0) {
          // We can only move layers before another layer
          // so work through overlays backwards, placing each one before the previous one
          const reversedOverlays = overlays.slice().reverse();
          reversedOverlays.slice(1).reduce((previousLayer, overlayId) => {
            const layerIds = overlayIdToLayerIds[overlayId];
            if (layerIds?.length) {
              moveAllLayersBefore(
                layerIds,
                previousLayer,
                map,
                layersToNextLayer,
              );
            }
            return layerIds;
          }, overlayIdToLayerIds[reversedOverlays[0]]);
        }
        if (baselayer) {
          if (overlays.length > 0) {
            layersToNextLayer[overlayIdToLayerIds[baselayer][0]] =
              overlayIdToLayerIds[overlays[0]][0];
            map?.moveLayer(
              overlayIdToLayerIds[baselayer][0],
              overlayIdToLayerIds[overlays[0]][0],
            );
          }
          // satellite underneath the AC base layer
          moveSatelliteLayer(map, overlayIdToLayerIds[baselayer][0]);
        } else if (overlays.length > 0) {
          moveSatelliteLayer(map, overlayIdToLayerIds[overlays[0]][0]);
        }
        // stop these being put behind the other layers in all the moving about
        try {
          map?.moveLayer(`boundary-${boundaryId}-layer-fill`, topLayer);
          map?.moveLayer(`boundary-${boundaryId}-layer-line`, topLayer);
        } catch (e) {
          // Ignore this error - if the boundaries don't exist yet then they'll be added
          // on top of the other layers anyway
        }
        layerDataArray.forEach(({ layerIds, id, sourceId, layer, vector }) => {
          if (id) {
            if (id.toString() === baselayer) {
              makeLayerVisible(layerIds, map);
            } else if (overlays.indexOf(id.toString()) > -1) {
              const source = map.getSource(sourceId) as
                | mapboxgl.RasterSource
                | mapboxgl.VectorSource;
              // check that source.tiles exists
              // sometimes source can be removed in the previous useEffect cleanup which then makes this source tiles undefined.
              if (source?.tiles) {
                if (layer.image_type === "VECTOR" && source?.tiles && vector) {
                  const filters: Record<string, string[]> = {};
                  searchParams.forEach((value, key) => {
                    if (key.startsWith(`${filterKeyPrefix}_${vector.id}_`)) {
                      const filterKey = key.replace(
                        `${filterKeyPrefix}_${vector.id}_`,
                        "",
                      );
                      if (filters[filterKey]) {
                        filters[filterKey].push(value);
                      } else {
                        filters[filterKey] = [value];
                      }
                    }
                  });
                  const newSource = replaceFiltersInUrls(
                    source as mapboxgl.VectorSource,
                    layer,
                    filters,
                  );
                  if (
                    newSource.tiles &&
                    JSON.stringify(newSource.tiles) !==
                      JSON.stringify(source?.tiles)
                  ) {
                    layerIds.forEach((layerId) => map.removeLayer(layerId));
                    map.removeSource(sourceId);
                    map.addSource(sourceId, newSource);
                    layerIds.forEach((layerId, index) => {
                      map.addLayer(
                        {
                          ...vector?.mapbox_styles[index],
                          maxzoom:
                            !vector.maxzoom || vector.maxzoom > 24
                              ? 24
                              : vector.maxzoom,
                          minzoom: vector.minzoom ?? 0,
                          id: layerId,
                          source: sourceId,
                          "source-layer": "default",
                        } as mapboxgl.AnyLayer,
                        layersToNextLayer[layerId],
                      );
                    });
                  }
                } else {
                  const min = searchParams.get(
                    `${overlayModiferKey}cmap${id}min`,
                  );
                  const max = searchParams.get(
                    `${overlayModiferKey}cmap${id}max`,
                  );
                  const lowOpacity =
                    searchParams.get(`${overlayModiferKey}cmap${id}start`) ===
                    "off"
                      ? 0
                      : 1;
                  const highOpacity =
                    searchParams.get(`${overlayModiferKey}cmap${id}end`) ===
                    "off"
                      ? 0
                      : 1;
                  const vegStrataToggles: (0 | 1)[] =
                    (searchParams
                      .get(`${overlayModiferKey}${id}vs`)
                      ?.split("_")
                      ?.map((x) => parseInt(x, 10)) as (0 | 1)[]) ||
                    defaultVegStrataToggles;
                  const minInt = min ? parseInt(min) : null;
                  const maxInt = max ? parseInt(max) : null;
                  const newSource = replaceColorMapInUrls(
                    source as mapboxgl.RasterSource,
                    layer,
                    minInt,
                    maxInt,
                    lowOpacity,
                    highOpacity,
                    vegStrataToggles,
                  );
                  if (
                    newSource.tiles &&
                    JSON.stringify(newSource.tiles) !==
                      JSON.stringify(source?.tiles)
                  ) {
                    map.removeLayer(layerIds[0]);
                    map.removeSource(sourceId);
                    map.addSource(sourceId, newSource);
                    map?.addLayer(
                      {
                        id: layerIds[0],
                        type: "raster",
                        source: sourceId,
                        minzoom: 2,
                        maxzoom: 24,
                        layout: {
                          visibility: "visible",
                        },
                      },
                      layersToNextLayer[layerIds[0]],
                    );
                  }
                }
              }
              makeLayerVisible(layerIds, map);
            } else {
              makeLayerInvisible(layerIds, map);
            }
          }
        }, null);
      } catch (e) {
        console.log(
          "Error moving layers - this is normal if the map is unmounted mid-effect and should resolve itself",
        );
      }
    }
  }, [
    map,
    overlays,
    baselayer,
    layerDataArray,
    searchParams,
    token,
    boundaryId,
    overlayModiferKey,
    filterKeyPrefix,
    moveSatelliteLayer,
  ]);
}

export function useSatelliteVisibility(
  map: mapboxgl.Map | null,
  satelliteKey: string,
) {
  const { data: style } = useStyle();
  const layers = useMemo(() => {
    if (!style?.layers) return [];
    return style?.layers?.map((layer) => layer.id);
  }, [style?.layers]);
  const [searchParams] = useSearchParams({ [satelliteKey]: "on" });

  const satellite = searchParams.get(satelliteKey) || "on";

  useEffect(() => {
    if (map) {
      layers.forEach((layer) => {
        map.setLayoutProperty(
          layer,
          "visibility",
          satellite === "on" ? "visible" : "none",
        );
      });
    }
  }, [satellite, map, layers]);
}
function useFitBoundary(
  map: mapboxgl.Map | null,
  boundary: BoundaryResponse | undefined,
) {
  // only fit boundary once per mount
  const boundaryFit = useRef(false);
  useEffect(() => {
    if (boundary && map && !boundaryFit.current) {
      const bounds = getBounds([boundary]);
      if (bounds) {
        map?.fitBounds([bounds.min, bounds.max], { padding: 20 });
        boundaryFit.current = true;
      }
    }
  }, [boundary, map]);
}
export function BoundaryView() {
  const { mapState, compareMapState } = useContext(MapContext);
  const { boundaryId } = useParams();
  const { data: boundary } = useUIQuery<BoundaryResponse>(
    ["boundary", { id: boundaryId as string }],
    true,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );
  const { setMenuContextOpen } = useContext(MenuOpenContext);
  useImagery(mapState);
  useImagery(compareMapState?.before);
  useImagery(compareMapState?.after, true);
  useSatelliteVisibility(mapState, "satellite");
  useSatelliteVisibility(compareMapState?.before, "satellite");
  useSatelliteVisibility(compareMapState?.after, "compareSatellite");
  useEffect(() => {
    if (setMenuContextOpen) {
      setMenuContextOpen(true);
    }
  }, [setMenuContextOpen]);
  useFitBoundary(mapState, boundary);
  // only need to do the right map, because the other will follow.
  useFitBoundary(compareMapState.before, boundary);
  return (
    <SideMenu>
      <VerticalTabs boundary={boundary} />
      <VectorPopup map={mapState} />
      <VectorPopup map={compareMapState.before} />
      <VectorPopup map={compareMapState.after} />
    </SideMenu>
  );
}
