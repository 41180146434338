import {
  Box,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Button,
  Skeleton,
} from "@mui/material";
import { useCallback, useState } from "react";
import { SideMenu } from "../components/SideMenu";
import { useUIQuery } from "../queries";
import { BoundaryResponse } from "../responseTypes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getGeometryMidPoint } from "../utils";
import { useAuth0 } from "@auth0/auth0-react";
import { FeatureFlagged, useFeatureFlags } from "../components/FeatureFlag";

export function BoundarySelectSideMenu() {
  const [keywords, setKeywords] = useState("");
  const { isLoading: isLoadingAuth } = useAuth0();
  const [selectedBoundary, setSelectedBoundary] =
    useState<BoundaryResponse | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const setFilter = (filter: string, values: string[]) => {
    searchParams.delete(filter);
    searchParams.delete("lng");
    searchParams.delete("lat");
    values.forEach((value) => searchParams.append(filter, value));
    navigate(`/?${searchParams.toString()}`, { replace: true });
  };

  const navigateToBoundary = (boundaryId: number, lat: number, lng: number) => {
    navigate(`/boundary/popup/${boundaryId}?lat=${lat}&lng=${lng}`, {
      state: { closedPopup: false },
    });
  };

  const setSelectedBoundaryAndUrl = (boundary: BoundaryResponse) => {
    const [lng, lat] = boundary.geom
      ? getGeometryMidPoint(boundary.geom)
      : [0, 0];
    navigateToBoundary(boundary.id, lat, lng);
    setSelectedBoundary(boundary);
  };
  const clearFilters = () => {
    searchParams.delete("target");
    searchParams.delete("state__country");
    searchParams.delete("client");
    searchParams.delete("boundaryState");
    setSelectedBoundary(null);
    navigate(`/?${searchParams.toString()}`);
  };
  const countryFilter = searchParams.getAll("state__country");
  const stateFilter = searchParams.getAll("boundaryState");
  const targetFilter = searchParams.getAll("target");
  const clientFilter = searchParams.getAll("client");

  const [
    showCountryInSearch,
    showStateInSearch,
    showTargetInSearch,
    isFetchWhenEmptyEnabled,
  ] = useFeatureFlags([
    "boundary.search.show.country",
    "boundary.search.show.state",
    "boundary.search.show.target",
    "boundary.search.fetch.when.empty",
  ]).flagList;
  const getOptionLabel = useCallback(
    (boundary: BoundaryResponse) => {
      let label = boundary.name;
      if (showTargetInSearch) {
        label += ` (${boundary.target_name})`;
      }
      if (showStateInSearch) {
        label += `, ${boundary.state_name}`;
      }
      if (showCountryInSearch) {
        label += `, ${boundary.country_name}`;
      }
      return label;
    },
    [showCountryInSearch, showStateInSearch, showTargetInSearch],
  );

  const { data: options, isLoading: isLoadingValues } = useUIQuery<
    Record<string, { field_id: number; label: string }[]>
  >(["boundary/values"]);
  const { data: boundarySearch, isFetching: isFetchingBoundary } = useUIQuery<
    BoundaryResponse[]
  >(
    ["boundary", { params: { keywords: keywords } }],
    !!keywords || isFetchWhenEmptyEnabled,
    "GET",
    {
      staleTime: 1000 * 60 * 5,
    },
  );
  const loadingAuthOrValues = isLoadingAuth || isLoadingValues;
  return (
    <SideMenu>
      <Box sx={{ m: 2 }}>
        <Typography variant="h5" component="h2">
          Filter Boundaries
        </Typography>
        <FeatureFlagged flag="boundary.select.search.filter">
          <FormControl fullWidth sx={{ mt: 2 }}>
            {loadingAuthOrValues ? (
              <Skeleton variant="rectangular" height={56} />
            ) : (
              <Autocomplete
                id={`boundary-search`}
                getOptionLabel={getOptionLabel}
                options={boundarySearch || []}
                noOptionsText={
                  keywords
                    ? "No results"
                    : "Search for a boundary based on name, state, country or target"
                }
                autoComplete
                loading={isFetchingBoundary}
                filterSelectedOptions
                value={selectedBoundary}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedBoundaryAndUrl(newValue);
                  } else {
                    setSelectedBoundary(null);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setKeywords(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"Search"} variant="outlined" />
                )}
              />
            )}
          </FormControl>
        </FeatureFlagged>
        <FeatureFlagged flag="boundary.select.country.filter">
          <FormControl fullWidth sx={{ mt: 2 }}>
            {loadingAuthOrValues ? (
              <Skeleton variant="rectangular" height={56} />
            ) : (
              <Autocomplete
                multiple
                autoComplete
                id="country-select"
                isOptionEqualToValue={(option, value) =>
                  option.field_id === value.field_id
                }
                getOptionLabel={(option) => option?.label}
                value={
                  (countryFilter.map((country) =>
                    options?.country?.find(
                      (option) => option.field_id === parseInt(country, 10),
                    ),
                  ) as { field_id: number; label: string }[]) || []
                }
                options={
                  options?.country ||
                  ([] as { field_id: number; label: string }[])
                }
                onChange={(event, value) =>
                  setFilter(
                    "state__country",
                    value.map((f) => f.field_id.toString()),
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Country" />
                )}
              />
            )}
          </FormControl>
        </FeatureFlagged>
        <FeatureFlagged flag="boundary.select.state.filter">
          <FormControl fullWidth sx={{ mt: 2 }}>
            {loadingAuthOrValues ? (
              <Skeleton variant="rectangular" height={56} />
            ) : (
              <Autocomplete
                multiple
                autoComplete
                id="state-select"
                isOptionEqualToValue={(option, value) =>
                  option.field_id === value.field_id
                }
                getOptionLabel={(option) => option?.label}
                value={
                  (stateFilter.map((state) =>
                    options?.state?.find(
                      (option) => option.field_id === parseInt(state, 10),
                    ),
                  ) as { field_id: number; label: string }[]) || []
                }
                options={
                  options?.state ||
                  ([] as { field_id: number; label: string }[])
                }
                onChange={(event, value) =>
                  setFilter(
                    "boundaryState",
                    value.map((f) => f.field_id.toString()),
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="State" />
                )}
              />
            )}
          </FormControl>
        </FeatureFlagged>
        <FeatureFlagged flag="boundary.select.target.filter">
          <FormControl fullWidth sx={{ mt: 2 }}>
            {loadingAuthOrValues ? (
              <Skeleton variant="rectangular" height={56} />
            ) : (
              <Autocomplete
                multiple
                autoComplete
                id="target-select"
                isOptionEqualToValue={(option, value) =>
                  option.field_id === value.field_id
                }
                getOptionLabel={(option) => option?.label}
                value={
                  (targetFilter.map((target) =>
                    options?.target?.find(
                      (option) => option.field_id === parseInt(target, 10),
                    ),
                  ) as { field_id: number; label: string }[]) || []
                }
                options={
                  options?.target ||
                  ([] as { field_id: number; label: string }[])
                }
                onChange={(event, value) =>
                  setFilter(
                    "target",
                    value.map((f) => f.field_id.toString()),
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Target" />
                )}
              />
            )}
          </FormControl>
        </FeatureFlagged>
        <FeatureFlagged flag="boundary.select.client.filter">
          <FormControl fullWidth sx={{ mt: 2 }}>
            {loadingAuthOrValues ? (
              <Skeleton variant="rectangular" height={56} />
            ) : (
              <Autocomplete
                multiple
                autoComplete
                id="client-select"
                isOptionEqualToValue={(option, value) =>
                  option.field_id === value.field_id
                }
                getOptionLabel={(option) => option?.label}
                value={
                  (clientFilter.map((client) =>
                    options?.client?.find(
                      (option) => option.field_id === parseInt(client, 10),
                    ),
                  ) as { field_id: number; label: string }[]) || []
                }
                options={
                  options?.client ||
                  ([] as { field_id: number; label: string }[])
                }
                onChange={(event, value) =>
                  setFilter(
                    "client",
                    value.map((f) => f.field_id.toString()),
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Client" />
                )}
              />
            )}
          </FormControl>
        </FeatureFlagged>
        <Box sx={{ mt: 2 }}>
          <Button
            onClick={() => {
              clearFilters();
            }}
          >
            Reset Filters
          </Button>
        </Box>
      </Box>
    </SideMenu>
  );
}
