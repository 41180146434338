import {
  List,
  ListItem,
  Typography,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useUIQuery } from "../../queries";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import {
  AcquisitionResponse,
  BoundaryResponse,
  LayerResponse,
} from "../../responseTypes";

export function BoundaryTab() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { boundaryId } = useParams();
  const { data: boundary } = useUIQuery<BoundaryResponse>(
    ["boundary", { id: boundaryId as string }],
    true,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );
  const { data: acquisitions } = useUIQuery<AcquisitionResponse[]>(
    ["acquisition", { params: { boundary: boundaryId as string } }],
    true,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );
  const firstAcquisition = acquisitions ? acquisitions[0] : null;
  return (
    <>
      <Typography component="h5" variant="h6">
        Boundary Information
      </Typography>
      <List dense>
        <ListItem>
          {boundary ? (
            <ListItemText
              primary={boundary.name}
              secondary={`${boundary.state_name} ${boundary.country_name}`}
            />
          ) : (
            <Skeleton width="100%" height="50px" />
          )}
        </ListItem>
        <ListItem>
          {boundary ? (
            <ListItemText
              primary={
                <>
                  <strong>Date:</strong>{" "}
                  {boundary?.date_added
                    ? new Date(boundary.date_added).toLocaleDateString()
                    : "-"}
                </>
              }
            />
          ) : (
            <Skeleton width="100%" height="30px" />
          )}
        </ListItem>
        <ListItem>
          {boundary ? (
            <ListItemText
              primary={
                <>
                  <strong>Target:</strong> {boundary.target_name}
                </>
              }
            />
          ) : (
            <Skeleton width="100%" height="30px" />
          )}
        </ListItem>
        <ListItem>
          {boundary ? (
            <ListItemText
              primary={
                <>
                  <strong>Area:</strong>{" "}
                  {boundary.area
                    ? (parseFloat(boundary.area) / 10000).toFixed(2) + " ha"
                    : "Unknown"}
                </>
              }
            />
          ) : (
            <Skeleton width="100%" height="30px" />
          )}
        </ListItem>
      </List>
      <Typography variant="h6">Latest Acquisition</Typography>
      <List dense>
        <ListItem>
          {firstAcquisition ? (
            <ListItemText
              primary={
                <>
                  <strong>Acquired:</strong>{" "}
                  {firstAcquisition.date_acquired
                    ? new Date(
                        firstAcquisition.date_acquired,
                      ).toLocaleDateString()
                    : "Unknown"}
                </>
              }
            />
          ) : (
            <Skeleton width="100%" height="50px" />
          )}
        </ListItem>
        <ListItem>
          {firstAcquisition ? (
            <ListItemText
              primary={
                <>
                  <strong>Altitude (ft):</strong> {firstAcquisition.altitude}
                </>
              }
            />
          ) : (
            <Skeleton width="100%" height="30px" />
          )}
        </ListItem>
        <ListItem>
          {firstAcquisition ? (
            <Stack direction="row" spacing={1}>
              <ListItemText
                primary={
                  <>
                    <strong>GSD (cm):</strong>
                  </>
                }
              />
              <ListItemText
                disableTypography
                primary={
                  <>
                    {firstAcquisition.layers.map((layer: LayerResponse) => {
                      if (!layer.gsd) {
                        return null;
                      }
                      return (
                        <ListItemText
                          key={layer.id}
                          primary={`${layer.image_type_name}: ${
                            layer.gsd * 100
                          }`}
                        />
                      );
                    })}
                  </>
                }
              />
            </Stack>
          ) : (
            <Skeleton width="100%" height="70px" />
          )}
        </ListItem>
        <ListItem>
          {firstAcquisition ? (
            <Stack direction="row" spacing={1}>
              <ListItemText
                sx={{ whiteSpace: "nowrap", flex: "1 0 auto" }}
                primary={
                  <>
                    <strong>Image Types:</strong>
                  </>
                }
              />
              <ListItemText
                disableTypography
                primary={
                  <ListItemText
                    primary={firstAcquisition.layers
                      .map((layer: LayerResponse) => layer.image_type_name)
                      .join(", ")}
                  />
                }
              />
            </Stack>
          ) : (
            <Skeleton width="100%" height="70px" />
          )}
        </ListItem>
      </List>
      <Button
        onClick={() => {
          searchParams.set("tab", "acquisitions");
          setSearchParams(searchParams);
        }}
      >
        See all acquisitions
      </Button>
    </>
  );
}
