import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useFeatureFlags } from "./FeatureFlag";
import { useState } from "react";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { documentationUrl, videoUrl } from "../env";

function setWelcomeInLocalStorage() {
  // set the local storage key "welcome" to the current time
  // so we know when the user welcome to the terms
  localStorage.setItem("welcome", Date.now().toString());
}

function shouldShowWelcome() {
  // if the user has not seen the welcome, show the modal
  const welcome = localStorage.getItem("welcome");
  if (!welcome) {
    return true;
  }
  const welcomeDate = new Date(parseInt(welcome, 10));
  const now = new Date();
  const oneMonth = 1000 * 60 * 60 * 24 * 30;
  return now.getTime() - welcomeDate.getTime() > oneMonth;
}
export function WelcomeModal() {
  const {
    flagList: [isWelcomeEnabled],
  } = useFeatureFlags(["welcome.modal"]);
  const [open, setOpen] = useState<boolean>(shouldShowWelcome());
  return (
    <Dialog
      maxWidth="lg"
      open={isWelcomeEnabled && open}
      aria-labelledby="alert-dialog-title"
      onClose={() => {
        setWelcomeInLocalStorage();
        setOpen(false);
      }}
    >
      <DialogTitle id="alert-dialog-title">Welcome</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          To view and analyse the data, select a boundary (using the dropdown or
          by selecting on the map) then click 'View Data'. Additional help can
          be accessed using the{" "}
          <span>
            <HelpIcon fontSize="small" />
          </span>{" "}
          icon at the top right of the screen - the help will automatically
          update based on the currently active view.{" "}
          {documentationUrl && (
            <>
              Alternatively,{" "}
              <a href={documentationUrl} download="GIUI-Documentation.pdf">
                you can download the documentation as a PDF.
              </a>
            </>
          )}
        </Typography>
        {videoUrl && (
          <>
            <Typography sx={{ mt: 2 }} variant="body1">
              See below for a video on how to use the tool.
            </Typography>
            <video
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "500px",
                objectFit: "contain",
              }}
              controls
              src={videoUrl}
              preload="auto"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setWelcomeInLocalStorage();
            setOpen(false);
          }}
        >
          Get Started
        </Button>
      </DialogActions>
    </Dialog>
  );
}
