import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, FormControl } from "@mui/material";
import { useUIQuery } from "../queries";
import { VectorResponse } from "../responseTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function FilterDialog({
  isOpen,
  onClose,
  vector,
  compare,
}: {
  isOpen: boolean;
  onClose: () => void;
  vector: VectorResponse;
  compare?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterKey = compare ? "compare_filter" : "filter";
  const getInitial = useCallback(() => {
    let initial: Record<string, any> =
      vector?.filter_fields?.reduce((acc, f) => {
        acc[f] = [];
        return acc;
      }, {} as any) || {};
    searchParams.forEach((value, key) => {
      if (key.startsWith(`${filterKey}_${vector.id}_`)) {
        initial[key.replace(`${filterKey}_${vector.id}_`, "")].push(value);
      }
    });
    return initial;
  }, [vector, searchParams, filterKey]);
  const [filter, setFilter] = useState(getInitial());
  useEffect(() => {
    setFilter(getInitial());
  }, [isOpen, getInitial, setFilter]);
  const fieldQueryParams = useMemo(
    () => vector?.filter_fields?.map((f) => ["field", f]) || [],
    [vector],
  );
  const { data } = useUIQuery<Record<string, string[]>>(
    [
      "geometries/values",
      {
        params: fieldQueryParams.concat([
          ["vector", vector?.id.toString() || ""],
        ]),
      },
    ],
    !!vector?.id,
  );
  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle>Filter layer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Filter one or more properties of the features. When filtered, only
          features that match all filtered properties will be shown.
        </DialogContentText>
        {data &&
          Object.entries(data).map(([property, values]) => {
            return (
              <FormControl key={property} fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  multiple
                  id={`autocomplete-${property}`}
                  isOptionEqualToValue={(option, value) => option === value}
                  options={values}
                  value={filter[property]}
                  onChange={(ev, values) => {
                    setFilter({
                      ...filter,
                      [property]: values,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={property}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            searchParams.forEach((value, key) => {
              if (key.startsWith(`${filterKey}_${vector.id}_`)) {
                searchParams.delete(key);
              }
            });
            setSearchParams(searchParams);
            setFilter(getInitial());
            onClose();
          }}
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            searchParams.forEach((value, key) => {
              if (key.startsWith(`${filterKey}_${vector.id}_`)) {
                searchParams.delete(key);
              }
            });
            Object.entries(filter).forEach(([property, values]) => {
              values.forEach((value: string) => {
                searchParams.append(
                  `${filterKey}_${vector.id}_${property}`,
                  value,
                );
              });
            });
            setSearchParams(searchParams);
            onClose();
          }}
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}
