import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { MenuOpenContext } from "../App";
import { useContext, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DrawerWidthContext } from "../App";
import { SIDE_CONTAINER_SIZE } from "./tabs/ImageryTab";
import { useSearchParams } from "react-router-dom";
import { useLogo } from "../routes/MapView";
import { useAuth0 } from "@auth0/auth0-react";
import { Skeleton } from "@mui/material";
import { FeatureFlagged } from "./FeatureFlag";

export function SideMenu({ children }: React.PropsWithChildren<{}>) {
  const { isLoading, isAuthenticated } = useAuth0();

  const { menuContextOpen } = useContext(MenuOpenContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { setDrawerWidth, defaultDrawerWidth, drawerWidth } =
    useContext(DrawerWidthContext);
  const [searchParams] = useSearchParams();
  const compare = searchParams.get("compare") === "on";
  const { logoUrl, showOnSidebar, isLoading: logoLoading } = useLogo();

  useEffect(() => {
    if (setDrawerWidth) {
      if (compare) {
        setDrawerWidth(defaultDrawerWidth + SIDE_CONTAINER_SIZE);
      } else {
        setDrawerWidth(defaultDrawerWidth);
      }
    }
    return () => {
      setDrawerWidth && setDrawerWidth(defaultDrawerWidth);
    };
  }, [compare, setDrawerWidth, defaultDrawerWidth]);
  if (!isLoading && !isAuthenticated) {
    return null;
  }
  return (
    <Drawer
      variant={!matches || !menuContextOpen ? "temporary" : "permanent"}
      open={menuContextOpen}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        width: matches ? drawerWidth : "100%",
        maxWidth: matches ? drawerWidth : "100%",
        flexShrink: 0,
        display: "block",
        [`& .MuiDrawer-paper`]: {
          width: matches ? drawerWidth : "100%",
          maxWidth: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ height: "100%", maxHeight: "100%" }}>{children}</Box>
      <FeatureFlagged flag="custom.logo">
        {(logoUrl && showOnSidebar) || logoLoading ? (
          <Box
            sx={{
              position: "fixed",
              width: 135,
              bottom: 16,
              left: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {logoLoading ? (
              <Skeleton variant="rectangular" height="40px" width="90px" />
            ) : (
              <img
                alt="Company Logo"
                src={logoUrl}
                style={{
                  maxHeight: "42px",
                  maxWidth: "100px",
                }}
              />
            )}
          </Box>
        ) : null}
      </FeatureFlagged>
    </Drawer>
  );
}
