import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useUIQuery } from "../queries";
import { MapContext, MenuOpenContext } from "../App";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getBounds, BoundaryBounds, getTopLayer } from "../utils";
import { BoundaryResponse } from "../responseTypes";
import { FeatureCollection } from "geojson";
// TODO: write types for mapbox-gl-compare
// @ts-ignore
import * as MapboxCompare from "mapbox-gl-compare";
import { DrawerWidthContext } from "../App";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../routes/MapView";
import * as env from "../env";
import { useStyle } from "../queries";
// @ts-ignore
mapboxgl.accessToken = env.mapboxToken;
type BoundaryClickEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
} & mapboxgl.EventData;

function useMapbox(
  enabled: boolean,
  containerRef: React.RefObject<HTMLDivElement>,
  lng: number,
  lat: number,
  zoom: number,
  beforeLoad: () => void,
  afterLoad: () => void,
) {
  const { data: style } = useStyle();
  const { mapState, setMapState, compareMapState } = useContext(MapContext);
  useEffect(() => {
    if (containerRef.current && enabled && style) {
      let startingLat = lat;
      let startingLng = lng;
      let startingZoom = zoom;
      if (compareMapState.before) {
        const center = compareMapState.before.getCenter();
        startingLat = center.lat;
        startingLng = center.lng;
        startingZoom = compareMapState.before.getZoom();
      }
      beforeLoad();
      const newMap = new mapboxgl.Map({
        container: containerRef.current,
        style: style, //style as mapboxgl.Style,
        center: [startingLng, startingLat],
        zoom: startingZoom,
        pitchWithRotate: false,
      });
      newMap.addControl(new mapboxgl.NavigationControl());
      newMap.addControl(new mapboxgl.ScaleControl());
      newMap.once("idle", () => {
        if (setMapState) {
          // mapbox does a weird flash when you first add a geojson source
          // this ensures it does it when it first loads instead of when the geometry loads.
          afterLoad();
          setMapState(newMap);
          newMap?.resize();
        }
      });

      return () => {
        newMap.remove();
        afterLoad();
        if (setMapState) {
          setMapState(null);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
  return mapState;
}

function useMapboxCompare(
  enabled: boolean,
  after: React.RefObject<HTMLDivElement>,
  before: React.RefObject<HTMLDivElement>,
  containerId: string,
  lng: number,
  lat: number,
  zoom: number,
  beforeLoad: () => void,
  afterLoad: () => void,
) {
  const { data: style } = useStyle();
  const { compareMapState, setCompareMapState, mapState } =
    useContext(MapContext);
  useEffect(() => {
    if (after.current && before.current && enabled) {
      let startingLat = lat;
      let startingLng = lng;
      let startingZoom = zoom;
      if (mapState) {
        const center = mapState.getCenter();
        startingLat = center.lat;
        startingLng = center.lng;
        startingZoom = mapState.getZoom();
      }
      beforeLoad();
      const newMapBefore = new mapboxgl.Map({
        container: before.current,
        style: style,
        center: [startingLng, startingLat],
        zoom: startingZoom,
        pitchWithRotate: false,
      });
      const newMapAfter = new mapboxgl.Map({
        container: after.current,
        style: style,
        center: [startingLng, startingLat],
        zoom: startingZoom,
        pitchWithRotate: false,
      });
      newMapAfter.addControl(new mapboxgl.NavigationControl());
      newMapBefore.addControl(new mapboxgl.NavigationControl());
      newMapAfter.addControl(new mapboxgl.ScaleControl());
      newMapBefore.addControl(new mapboxgl.ScaleControl());

      // @ts-ignore
      const compare = new MapboxCompare(
        newMapBefore,
        newMapAfter,
        containerId,
        {
          mousemove: false, // Optional. Set to true to enable swiping during cursor movement.
          orientation: "vertical", // Optional. Sets the orientation of swiper to horizontal or vertical, defaults to vertical
        },
      );
      let beforeMap: null | mapboxgl.Map = null;
      let afterMap: null | mapboxgl.Map = null;
      newMapBefore.once("idle", (event) => {
        beforeMap = newMapBefore;
        if (setCompareMapState && afterMap) {
          afterLoad();

          setCompareMapState({ before: beforeMap, after: afterMap });
        }
      });
      newMapAfter.once("idle", (event) => {
        afterMap = newMapAfter;
        if (setCompareMapState && beforeMap) {
          afterLoad();

          setCompareMapState({ before: beforeMap, after: afterMap });
        }
      });

      return () => {
        compare.remove();
        newMapBefore.remove();
        newMapAfter.remove();
        afterLoad();
        if (setCompareMapState) {
          setCompareMapState({ before: null, after: null });
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
  return compareMapState;
}

function useMapBoundaries(
  map: mapboxgl.Map | null,
  onClick: (id: number, lat: number, lng: number) => void,
  outlineColor: string = "#00FF00",
  outlineWidth: number = 3,
) {
  const [searchParams] = useSearchParams();
  const { setIsMapLoadingFlag } = useContext(MapContext);
  const params = ["state__country", "boundaryState", "target", "client"].reduce(
    (acc, param) => {
      // Can't use state because it's used by auth0 as a callback
      const upstreamParam = param === "boundaryState" ? "state" : param;
      return acc.concat(
        searchParams.getAll(param).map((p) => [upstreamParam, p]),
      );
    },
    [] as string[][],
  );
  const { data: boundaries, isLoading } = useUIQuery<BoundaryResponse[]>(
    params.length > 0 ? ["boundary", { params: params }] : ["boundary"],
    true,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );
  useEffect(() => {
    // set is map loading flag for "boundary"
    if (setIsMapLoadingFlag) {
      setIsMapLoadingFlag("boundary", isLoading);
    }
  }, [isLoading, setIsMapLoadingFlag]);
  const boundaryBounds: BoundaryBounds | null = useMemo(
    () => getBounds(boundaries),
    [boundaries],
  );
  const [fillLayerIds, setFillLayerIds] = useState<(string | undefined)[]>([]);
  const location = useLocation();
  const { boundaryId } = useParams();
  const showAllBoundaries =
    location.pathname === "/" ||
    location.pathname.startsWith("/boundary/popup/");
  const singleBoundaryToShow =
    !showAllBoundaries && boundaryId ? parseInt(boundaryId, 10) : null;
  useEffect(() => {
    if (map && boundaries) {
      const boundaryIds = boundaries.map((boundary: BoundaryResponse) => {
        if (singleBoundaryToShow && boundary.id !== singleBoundaryToShow) {
          // don't show this boundary because it's not the boundary that matches the
          // url parameter
          return {};
        }
        const sourceId = `boundary-${boundary.id}-source`;
        const layerFillId = `boundary-${boundary.id}-layer-fill`;
        const layerLineId = `boundary-${boundary.id}-layer-line`;
        map.addSource(sourceId, {
          type: "geojson",
          // This accepts MultiPolygon, idk why the types are this way
          data: boundary.geom as unknown as FeatureCollection,
        });
        const topLayer = getTopLayer(map)?.id;
        map.addLayer(
          {
            id: layerLineId,
            type: "line",
            source: sourceId,
            paint: {
              "line-color": outlineColor,
              "line-width": outlineWidth,
            },
          },
          topLayer,
        );
        map.addLayer(
          {
            id: layerFillId,
            type: "fill",
            source: sourceId,
            layout: {
              visibility: singleBoundaryToShow ? "none" : "visible",
            },
            paint: {
              "fill-color": "#000000",
              "fill-antialias": false,
              "fill-opacity": 0,
            },
          },
          topLayer,
        );
        if (singleBoundaryToShow) {
          return {
            layerLineId,
            sourceId,
            layerFillId,
          };
        }
        const onMouseEnter = (ev: mapboxgl.MapMouseEvent) => {
          map.getCanvas().style.cursor = "pointer";
        };
        const onMouseLeave = (ev: mapboxgl.MapMouseEvent) => {
          map.getCanvas().style.cursor = "";
        };
        map.on(`mouseenter`, layerFillId, onMouseEnter);
        map.on(`mouseleave`, layerFillId, onMouseLeave);

        return {
          layerFillId,
          layerLineId,
          sourceId,
          onMouseEnter,
          onMouseLeave,
        };
      });
      if (!singleBoundaryToShow) {
        // don't need fill layers on single boundary
        setFillLayerIds(boundaryIds.map((boundary) => boundary.layerFillId));
      }
      return () => {
        try {
          boundaryIds.forEach(
            ({
              layerFillId,
              layerLineId,
              sourceId,
              onMouseEnter,
              onMouseLeave,
            }: {
              layerFillId?: string;
              layerLineId?: string;
              sourceId?: string;
              onMouseEnter?: (ev: mapboxgl.MapMouseEvent) => void;
              onMouseLeave?: (ev: mapboxgl.MapMouseEvent) => void;
            }) => {
              if (map) {
                if (layerFillId) {
                  map.removeLayer(layerFillId);
                  if (onMouseEnter) {
                    map.off(`mouseenter`, layerFillId, onMouseEnter);
                  }
                  if (onMouseLeave) {
                    map.off(`mouseleave`, layerFillId, onMouseLeave);
                  }
                }
                if (layerLineId) {
                  map.removeLayer(layerLineId);
                }
                if (sourceId) {
                  map.removeSource(sourceId);
                }
              }
            },
          );
        } catch (e) {
          console.log(
            "Error removing boundary layers - this is normal if the map is unmounted",
          );
          console.log(e);
        }
        setFillLayerIds([]);
      };
    }
  }, [
    boundaries,
    map,
    onClick,
    singleBoundaryToShow,
    outlineColor,
    outlineWidth,
  ]);

  // Click listeners not required for comapre map
  useEffect(() => {
    if (
      map &&
      (location.pathname === "/" ||
        location.pathname.startsWith("/boundary/popup/"))
    ) {
      if (location.pathname === "/" && boundaryBounds) {
        map.fitBounds([boundaryBounds.min, boundaryBounds.max], {
          padding: 20,
        });
      }
      const listeners = fillLayerIds.reduce(
        (listenerAcc, layerId) => {
          if (layerId) {
            const id = parseInt(layerId.replace(/^(boundary-)/, ""), 10);
            const onBoundaryClick = (ev: BoundaryClickEvent) => {
              onClick(id, ev.lngLat.lat, ev.lngLat.lng);
            };
            map.on("click", layerId, onBoundaryClick);
            listenerAcc.push({
              layerId: layerId,
              onBoundaryClick,
            });
          }
          return listenerAcc;
        },
        [] as {
          layerId: string;
          onBoundaryClick: (ev: BoundaryClickEvent) => void;
        }[],
      );
      return () => {
        listeners.forEach((listener) => {
          map.off("click", listener.layerId, listener.onBoundaryClick);
        });
      };
    }
  }, [onClick, location.pathname, map, fillLayerIds, boundaryBounds]);
}

export function MapboxMap() {
  const { isLoading, isAuthenticated } = useAuth0();
  const mapContainer = useRef<HTMLDivElement>(null);
  const compareContainer = useRef<HTMLDivElement>(null);
  const beforeContainer = useRef<HTMLDivElement>(null);
  const afterContainer = useRef<HTMLDivElement>(null);
  // Controls whether or not we want to block out the map and and show the spinner
  // This is done on a delay of 500ms to avoid flashing. If the global mapLoading
  // context is false after the delay then isSpinnerShowing won't be set to true.
  // One exception is on the initial mount, we always show the spinner.
  const [isSpinnerShowing, setIsSpinnerShowing] = useState<boolean>(true);
  const { compareWidthSet } = useContext(DrawerWidthContext);

  const lng = 133;
  const lat = -26.5;
  const zoom = 3.7;
  const { compareMapState, mapState, isMapLoading, setIsMapLoadingFlag } =
    useContext(MapContext);
  useEffect(() => {
    if (isMapLoading) {
      const timeout = setTimeout(() => {
        setIsSpinnerShowing(true);
      }, 500);
      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setIsSpinnerShowing(false);
      }, 150);
      return () => clearTimeout(timeout);
    }
  }, [isMapLoading]);
  const compareMapLoaded = !!compareMapState?.before;
  const mapLoaded = !!mapState;
  const showMap = !compareWidthSet || (!compareMapLoaded && mapLoaded);
  const showCompare = compareWidthSet || (!mapLoaded && compareMapLoaded);
  const beforeLoadMain = () =>
    setIsMapLoadingFlag && setIsMapLoadingFlag("mainMap", true);
  const afterLoadMain = () =>
    setIsMapLoadingFlag && setIsMapLoadingFlag("mainMap", false);
  const beforeLoadCompare = () =>
    setIsMapLoadingFlag && setIsMapLoadingFlag("compareMap", true);
  const afterLoadCompare = () =>
    setIsMapLoadingFlag && setIsMapLoadingFlag("compareMap", false);
  useMapbox(
    showMap && (!isLoading || isAuthenticated),
    mapContainer,
    lng,
    lat,
    zoom,
    beforeLoadMain,
    afterLoadMain,
  );
  useMapboxCompare(
    showCompare && (!isLoading || isAuthenticated),
    beforeContainer,
    afterContainer,
    "#compare-container",
    lng,
    lat,
    zoom,
    beforeLoadCompare,
    afterLoadCompare,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { drawerWidth } = useContext(DrawerWidthContext);
  const { menuContextOpen } = useContext(MenuOpenContext);

  useEffect(() => {
    if (mapState) {
      mapState.resize();
    }
  }, [mapState, location, drawerWidth, menuContextOpen]);
  useEffect(() => {
    if (compareMapState.before && compareMapState.after) {
      compareMapState.before.resize();
      compareMapState.after.resize();
    }
  }, [location, drawerWidth, menuContextOpen, compareMapState]);
  const navigateToBoundary = useCallback(
    (boundaryId: number, lat: number, lng: number) => {
      navigate(`/boundary/popup/${boundaryId}?lat=${lat}&lng=${lng}`, {
        state: { closedPopup: false },
      });
      // note: no deps because of this:
      // https://github.com/remix-run/react-router/issues/7634
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useMapBoundaries(mapState, navigateToBoundary);
  useMapBoundaries(compareMapState?.before, navigateToBoundary);
  useMapBoundaries(compareMapState?.after, navigateToBoundary);
  return (
    <div
      style={{
        width: "100%",
        flex: "1 0 auto",
        flexGrow: 1,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          ...(showMap ? {} : { display: "none" }),
          zIndex: showMap && mapLoaded ? 2 : 1,
        }}
      >
        <div
          ref={mapContainer}
          className="mapboxgl-map"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        />
      </div>
      <div
        ref={compareContainer}
        id="compare-container"
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: showCompare && compareMapLoaded ? 2 : 1,
          ...(showCompare ? {} : { display: "none" }),
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          ref={beforeContainer}
          id="before"
        />
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          ref={afterContainer}
          id="after"
        />
      </div>
      <Backdrop
        sx={{
          zIndex: 3,
          position: "absolute",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        open={isSpinnerShowing && (isAuthenticated || isLoading)}
      >
        <CircularProgress size={60} color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{
          zIndex: 3,
          position: "absolute",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        open={!isAuthenticated && !isLoading}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            You must be logged in use the {process.env.REACT_APP_SITE_TITLE}.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LoginButton />
          </Box>
        </Box>
      </Backdrop>
    </div>
  );
}
