import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MapView } from "./routes/MapView";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { ConfirmProvider } from "material-ui-confirm";

import { BoundaryPopup } from "./routes/BoundaryPopup";
import { BoundaryView } from "./routes/BoundaryView";
import { BoundarySelectSideMenu } from "./routes/BoundarySelectSideMenu";
import { SIDE_CONTAINER_SIZE } from "./components/tabs/ImageryTab";
import { SnackbarProvider } from "notistack";
import { FeatureFlagged } from "./components/FeatureFlag";
import * as env from "./env";
import { useContent } from "./components/MarkdownContent";
import { AuthenticatedOnly } from "./components/AuthenticatedOnly";

const LoginRedirect = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);
  return null;
};
function AppRoutes() {
  const { isLoading } = useAuth0();
  const { setIsMapLoadingFlag } = React.useContext(MapContext);
  useEffect(() => {
    if (!isLoading && setIsMapLoadingFlag) {
      // make sure we have the content
      setIsMapLoadingFlag("authentication", false);
    }
  }, [setIsMapLoadingFlag, isLoading]);
  // make sure it loads first
  useContent();
  return (
    <BrowserRouter basename="">
      <Routes>
        <Route path="/" element={<MapView />}>
          <Route path="/login" element={<LoginRedirect />} />
          <Route
            index
            element={
              <AuthenticatedOnly>
                <FeatureFlagged flag="boundary.select.view">
                  <BoundarySelectSideMenu />
                </FeatureFlagged>
              </AuthenticatedOnly>
            }
          />
          <Route
            path="/boundary/view/:boundaryId"
            element={
              <AuthenticatedOnly>
                <FeatureFlagged flag="boundary.view">
                  <BoundaryView />
                </FeatureFlagged>
              </AuthenticatedOnly>
            }
          />
          <Route
            path="/boundary/popup/:boundaryId"
            element={
              <AuthenticatedOnly>
                <FeatureFlagged flag="boundary.popup.view">
                  <BoundaryPopup />
                </FeatureFlagged>
              </AuthenticatedOnly>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
const queryClient = new QueryClient();
const theme = createTheme({
  palette: {
    primary: {
      dark: "#206235",
      main: "#2e8c4d",
      light: "#57a370",
    },
    secondary: {
      dark: "#14a37f",
      main: "#1de9b6",
      light: "#4aedc4",
    },
  },
});
const defaultDrawerWidth = 450;
const intialDrawerWidth =
  new URLSearchParams(window.location.search).get("compare") === "on"
    ? defaultDrawerWidth + SIDE_CONTAINER_SIZE
    : defaultDrawerWidth;

export const DrawerWidthContext = React.createContext<{
  drawerWidth: number;
  defaultDrawerWidth: number;
  setDrawerWidth: null | ((drawerWidth: number) => void);
  compareWidthSet: boolean;
}>({
  drawerWidth: defaultDrawerWidth,
  defaultDrawerWidth,
  compareWidthSet: false,
  setDrawerWidth: null,
});

export type MapBoxState = null | mapboxgl.Map;

export const MapContext = React.createContext<{
  setIsMapLoadingFlag: null | ((flag: string, on: boolean) => void);
  isMapLoading: boolean;
  mapState: MapBoxState;
  setMapState: null | ((map: MapBoxState) => void);
  compareMapState: { before: MapBoxState; after: MapBoxState };
  setCompareMapState:
    | null
    | ((state: { before: MapBoxState; after: MapBoxState }) => void);
}>({
  mapState: null,
  setMapState: null,
  compareMapState: { before: null, after: null },
  setCompareMapState: null,
  setIsMapLoadingFlag: null,
  isMapLoading: true,
});

export const MenuOpenContext = React.createContext<{
  menuContextOpen: boolean;
  setMenuContextOpen: null | React.Dispatch<React.SetStateAction<boolean>>;
}>({
  menuContextOpen: true,
  setMenuContextOpen: null,
});
function App() {
  useEffect(() => {
    // make 10 requests to each tile server to wake up the lambda
    Array.from({ length: 10 }).forEach(() => {
      fetch(
        `${env.actilesProto}://${env.actilesProto === "http" ? "" : "a."}${
          env.actilesCogDomain
        }/healthz?_=${Date.now()}`,
      );
      fetch(
        `${env.actilesProto}://${env.actilesMvtDomain}/healthz?_=${Date.now()}`,
      );
    });
  }, []);
  const [mapLoadingFlags, setIsMapLoadingFlags] = React.useState(
    new Set(["authentication"]),
  );
  const setIsMapLoadingFlag = React.useCallback(
    (flag: string, on: boolean) => {
      if (on) {
        setIsMapLoadingFlags((flags) => new Set(flags).add(flag));
      } else {
        setIsMapLoadingFlags((flags) => {
          const newFlags = new Set(flags);
          newFlags.delete(flag);
          return newFlags;
        });
      }
    },
    [setIsMapLoadingFlags],
  );
  const isMapLoading = mapLoadingFlags.size > 0;
  const [mapState, setMapState] = React.useState<MapBoxState>(null);
  const [drawerWidth, setDrawerWidth] =
    React.useState<number>(intialDrawerWidth);
  const [compareMapState, setCompareMapState] = React.useState<{
    before: MapBoxState;
    after: MapBoxState;
  }>({ after: null, before: null });
  const [menuContextOpen, setMenuContextOpen] = React.useState<boolean>(true);
  return (
    <DrawerWidthContext.Provider
      value={{
        drawerWidth,
        defaultDrawerWidth,
        setDrawerWidth,
        compareWidthSet: drawerWidth !== defaultDrawerWidth,
      }}
    >
      <MenuOpenContext.Provider value={{ menuContextOpen, setMenuContextOpen }}>
        <MapContext.Provider
          value={{
            mapState,
            setMapState,
            compareMapState,
            setCompareMapState,
            setIsMapLoadingFlag,
            isMapLoading,
          }}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <ConfirmProvider>
                <Auth0Provider
                  domain={env.auth0Domain || ""}
                  clientId={env.auth0ClientId || ""}
                  authorizationParams={{
                    audience: env.auth0Audience,
                    redirect_uri: window.location.origin,
                  }}
                >
                  <QueryClientProvider client={queryClient}>
                    <AppRoutes />
                  </QueryClientProvider>
                </Auth0Provider>
              </ConfirmProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </MapContext.Provider>
      </MenuOpenContext.Provider>
    </DrawerWidthContext.Provider>
  );
}

export default App;
