import { Typography, List, ListItem, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { LayerResponse } from "../../responseTypes";
import { useSelectedAcquisition } from "./AcquisitionsTab";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useUIQuery } from "../../queries";
import { FeatureFlagged } from "../FeatureFlag";
function DownloadButton({ layer }: { layer: LayerResponse }) {
  const [isStarting, setIsStarting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  // Maybe should be useMutation as we are POSTing and it records the request but... this should be fine.
  const { data: fetchUrl } = useUIQuery<{ url?: string }>(
    ["layer", { id: layer.id, urlSuffix: "download" }],
    isStarting && !isDownloading,
    "POST",
    {
      gcTime: Infinity,
      staleTime: Infinity,
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );
  useEffect(() => {
    if (fetchUrl?.url) {
      setIsDownloading(true);
      setIsStarting(false);
      window.open(fetchUrl.url, "_self", "noopener noreferrer");
    }
  }, [fetchUrl, layer.image_type]);

  return (
    <LoadingButton
      disabled={isDownloading}
      startIcon={<SaveIcon />}
      loading={isStarting}
      sx={{
        overflowWrap: "break-word",
        wordBreak: "break-all",
        textAlign: "left",
      }}
      onClick={() => {
        setIsStarting(true);
      }}
      loadingPosition="start"
    >
      {layer.name}
      {isDownloading ? " (download started)" : ""}
    </LoadingButton>
  );
}
export function DownloadTab() {
  const { selectedAcquisitions, isLoading } = useSelectedAcquisition();

  const downloadableLayers = selectedAcquisitions?.reduce(
    (acc, acquisition) =>
      acc +
      acquisition.layers.filter((layer) => !layer.block_client_download).length,
    0,
  );
  return (
    <>
      <Typography component="h5" variant="h6">
        Download
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        The following imagery data is available for download. By downloading the
        imagery you agree to only use it for agreed purposes and not to
        distribute it without permission.
      </Typography>
      <List dense>
        {isLoading && (
          <>
            <ListItem>
              <Skeleton variant="rectangular" height={36.5} width="100%" />
            </ListItem>
            <ListItem>
              <Skeleton variant="rectangular" height={36.5} width="100%" />
            </ListItem>
            <ListItem>
              <Skeleton variant="rectangular" height={36.5} width="100%" />
            </ListItem>
            <ListItem>
              <Skeleton variant="rectangular" height={36.5} width="100%" />
            </ListItem>
            <ListItem>
              <Skeleton variant="rectangular" height={36.5} width="100%" />
            </ListItem>
          </>
        )}
        {!isLoading && !downloadableLayers && (
          <Typography>
            <strong>
              No Data Available for download. Have you selected the correct
              acquisition?
            </strong>
          </Typography>
        )}
        {selectedAcquisitions.flatMap((acquisition) => {
          return acquisition.layers.map((layer) => {
            if (layer.block_client_download) {
              return null;
            }
            return (
              <FeatureFlagged
                key={layer.id}
                flag={`download.${layer.image_type.toLowerCase()}`}
              >
                <ListItem dense sx={{ pt: 0, pb: 0 }} key={layer.id}>
                  <DownloadButton layer={layer} />
                </ListItem>
              </FeatureFlagged>
            );
          });
        })}
      </List>
    </>
  );
}
