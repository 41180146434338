import { IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { MapboxMap } from "../components/MapboxMap";
import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { MenuOpenContext } from "../App";
import { useUIQuery } from "../queries";
import { ClientResponse } from "../responseTypes";
import { FeatureFlagged } from "../components/FeatureFlag";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { useSearchParams } from "react-router-dom";
import { HelpModal } from "../components/HelpModal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TermsModal } from "../components/TermsModal";
import { WelcomeModal } from "../components/WelcomeModal";
import { AuthenticatedOnly } from "../components/AuthenticatedOnly";

function LogoutButton() {
  const { logout } = useAuth0();
  return (
    <div
      style={{
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        height: 49,
      }}
    >
      <Button
        style={{}}
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
        color="inherit"
      >
        Log out
      </Button>
    </div>
  );
}
export function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div
      style={{
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        height: 49,
      }}
    >
      <Button color="inherit" onClick={() => loginWithRedirect()}>
        Login
      </Button>
    </div>
  );
}

function ContextualHelp() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => {
          searchParams.set("help", "true");
          setSearchParams(searchParams);
        }}
      >
        <HelpIcon />
      </IconButton>
      <HelpModal />
    </>
  );
}
export function useLogo(): {
  logoUrl: string;
  showAtTop: boolean;
  showOnSidebar: boolean;
  isLoading: boolean;
} {
  const {
    isAuthenticated,
    getIdTokenClaims,
    isLoading: isLoginLoading,
  } = useAuth0();
  const [clientId, setClientId] = useState<number | null>(null);
  const { data: clientData, isLoading } = useUIQuery<ClientResponse>(
    ["client", { id: clientId as number }],
    isAuthenticated && !!clientId,
    "GET",
    {
      gcTime: 24 * 60 * 60 * 1000,
      staleTime: 24 * 60 * 60 * 1000,
      retry: true,
      retryOnMount: true,
    },
  );
  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((claims) => {
        const clients = claims?.["https://arborcarbon.com.au/clients"] || [];
        // if * is in clients, then don't show any logo
        if (clients.includes("*")) {
          setClientId(null);
        }
        // otherwise use the first client id
        else if (clients.length > 0) {
          setClientId(parseInt(clients[0].replace("client_", ""), 10));
        } else {
          setClientId(null);
        }
      });
    }
    setClientId(null);
  }, [isAuthenticated, getIdTokenClaims]);
  return {
    logoUrl: clientData?.logo_image,
    showAtTop: !!clientData?.show_logo_at_top,
    showOnSidebar: !!clientData?.show_logo_on_sidebar,
    isLoading:
      clientId !== null && isLoading && (isLoginLoading || isAuthenticated),
  };
}

function TopBar() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { logoUrl, showAtTop, isLoading: logoLoading } = useLogo();
  const { menuContextOpen, setMenuContextOpen } = useContext(MenuOpenContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => {
            if (setMenuContextOpen && isAuthenticated) {
              setMenuContextOpen((prev) => !prev);
            }
          }}
        >
          {menuContextOpen && (isAuthenticated || isLoading) ? (
            <CloseIcon />
          ) : (
            <MenuIcon />
          )}
        </IconButton>

        <Typography variant="h6" component="div" sx={{}}>
          {matches
            ? process.env.REACT_APP_SITE_TITLE
            : process.env.REACT_APP_SHORT_TITLE}
        </Typography>

        <Box sx={{ mr: "auto", ml: 1 }}>
          <img
            src={"/android-chrome-192x192.png"}
            alt=""
            style={{
              filter: "brightness(0) invert(1)",
              maxHeight: "30px",
              verticalAlign: "middle",
            }}
          />
        </Box>
        <FeatureFlagged flag="custom.logo">
          {(logoUrl && showAtTop) || logoLoading ? (
            <Box sx={{ mr: 2, ml: 2 }}>
              {logoLoading ? (
                <Skeleton variant="rectangular" height="40px" width="90px" />
              ) : (
                <img
                  src={logoUrl}
                  alt="Company Logo"
                  style={{
                    maxHeight: "42px",
                    maxWidth: "100px",
                    verticalAlign: "middle",
                  }}
                />
              )}
            </Box>
          ) : null}
        </FeatureFlagged>
        <ContextualHelp />
        {isLoading && (
          <Button>
            <Skeleton width="60px" />
          </Button>
        )}
        {!isLoading && isAuthenticated && user ? (
          <LogoutButton />
        ) : (
          !isLoading && <LoginButton />
        )}
      </Toolbar>
    </AppBar>
  );
}

export function useSuspendedRedirect() {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((claims) => {
        const suspended =
          claims?.["https://arborcarbon.com.au/suspended"] || false;
        if (suspended) {
          window.location.href = "/suspended.html";
        }
      });
    }
  }, [isAuthenticated, getIdTokenClaims]);
}

export function MapView() {
  const qc = useQueryClient();
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    if (!isAuthenticated) {
      qc.clear();
    }
  }, [qc, isAuthenticated]);
  useSuspendedRedirect();
  const redirect = useUIQuery<{ custom_domain: boolean; domain: string }>(
    ["domain"],
    isAuthenticated,
    "GET",
    {
      // 1 day
      staleTime: 24 * 60 * 60 * 1000,
    },
  );
  useEffect(() => {
    if (
      redirect?.data?.custom_domain &&
      redirect?.data?.domain &&
      redirect.data.domain !== window.location.hostname
    ) {
      // get current protocol
      const proto = window.location.protocol;
      window.location.href = `${proto}//${redirect.data.domain}`;
    }
  }, [redirect]);
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <TopBar />
      <Outlet />
      <Box
        component="main"
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Toolbar />
        <MapboxMap />
        <AuthenticatedOnly>
          <WelcomeModal />
          <TermsModal />
        </AuthenticatedOnly>
      </Box>
    </Box>
  );
}
