import { useAuth0 } from "@auth0/auth0-react";
import { useAccessToken, useUIQuery } from "../queries";
import { FeatureFlagOverridesResponse } from "../responseTypes";

export function useFeatureFlags(flags: string[]) {
  const { user } = useAuth0();
  const token = useAccessToken();
  const { data: flagValues } = useUIQuery<FeatureFlagOverridesResponse>(
    [
      "flag",
      {
        extraKey: token ? user?.sub : "anonymous",
      },
    ],
    true,
    "GET",
    {
      // 5 Minutes
      staleTime: 1000 * 60 * 5,
    },
    false,
  );
  return {
    flagList: flags.map((flag) => flagValues?.[flag] || false),
    flagObject: flagValues,
  };
}

export function FeatureFlagged({
  children,
  flag,
  inverted,
}: React.PropsWithChildren<{
  flag?: string;
  inverted?: boolean;
}>) {
  const { user } = useAuth0();
  const token = useAccessToken();
  const { data: flags } = useUIQuery<FeatureFlagOverridesResponse>(
    [
      "flag",
      {
        extraKey: token ? user?.sub : "anonymous",
      },
    ],
    true,
    "GET",
    {
      // 5 Minutes
      staleTime: 1000 * 60 * 5,
    },
    false,
  );
  const isFlagOn = flag && (inverted ? !flags?.[flag] : flags?.[flag]);
  if (!flag && token) {
    // only show to logged in users
    return <>{children}</>;
  } else if (isFlagOn) {
    return <>{children}</>;
  }
  return null;
}
