import {
  Skeleton,
  List,
  Box,
  ListItem,
  ListItemText,
  Typography,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  Grid,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LaunchIcon from "@mui/icons-material/Launch";
import { useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MapContext } from "../../App";
import { useStatistics, useUIQuery } from "../../queries";
import { useOverlays } from "./ImageryTab";
import { useSelectedAcquisition } from "./AcquisitionsTab";
import { FeatureCollection } from "geojson";
import { saveAs } from "file-saver";
import { LayerResponse, VectorResponse } from "../../responseTypes";
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useResetCompare, useStableSetSearchParams } from "../../utils";
import { Geometries } from "../../types";
Chart.register(ArcElement, Tooltip, Legend, Title);
export const DRAW_STYLE = [
  {
    id: "highlight-active-points",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 10,
      "circle-color": "#1CCC00",
    },
  },
  {
    id: "points-are-blue",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["==", "active", "false"],
    ],
    paint: {
      "circle-radius": 10,
      "circle-color": "#1CCC00",
    },
  },
  // ACTIVE (being drawn)
  // line stroke
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#D20C0C",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  // polygon fill
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": "#D20C0C",
      "fill-outline-color": "#D20C0C",
      "fill-opacity": 0.4,
    },
  },
  // polygon mid points
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#D20C0C",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  // vertex point halos
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#FFF",
    },
  },
  // vertex points
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#D20C0C",
    },
  },

  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 3,
    },
  },
  // polygon fill
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    paint: {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.4,
    },
  },
  // polygon outline
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 3,
    },
  },
];
const HEADINGS = ["Source", "Layer", "Min", "Max", "Mean"];

function oneDecimalPlace(num: number) {
  return (Math.round(num * 10) / 10).toFixed(1);
}
function DownloadButton({
  rows,
  label,
  filenamePrefix,
  isVegStrata,
  statsTitle,
}: {
  rows: StatisticData[];
  label: string;
  filenamePrefix: string;
  isVegStrata?: boolean;
  statsTitle: string;
}) {
  return (
    <Button
      startIcon={<FileDownloadIcon />}
      onClick={() => {
        const headings = isVegStrata
          ? rows.reduce(
              (acc, value) => {
                // not all veg strata have the same labels
                // need to combine them all into one list with no duplicates
                Object.values(value.layer.veg_strata || {}).forEach(
                  (vegStrataLabel) => {
                    if (!acc.includes(vegStrataLabel)) {
                      acc.push(vegStrataLabel);
                    }
                  },
                );
                return acc;
              },
              ["name"] as string[],
            )
          : HEADINGS;
        const heading = [headings.join(",")];
        let rowMappingFn = (row: StatisticData): string => {
          return [
            statsTitle,
            row.name,
            `${oneDecimalPlace(row.min)}${row.suffix}`,
            `${oneDecimalPlace(row.max)}${row.suffix}`,
            `${oneDecimalPlace(row.mean)}${row.suffix}`,
          ].join(",");
        };
        if (isVegStrata) {
          rowMappingFn = (row: StatisticData): string => {
            // invert key to value of row.layer.veg_strata
            const vegStrataLabelToIndex = Object.entries(
              row.layer.veg_strata || {},
            ).reduce(
              (acc, [key, value]) => {
                acc[value] = key;
                return acc;
              },
              {} as { [key: string]: string },
            );
            return headings
              .map((heading) => {
                if (heading === "name") {
                  return row.name;
                }
                // need to see if this row actually has a value for the heading otherwise return an empty string
                const index = vegStrataLabelToIndex[heading];
                if (index !== undefined) {
                  return `${oneDecimalPlace(
                    (row.histogram[0][parseInt(index, 10)] / row.count) * 100,
                  )}%`;
                }
                return "";
              })
              .join(",");
          };
        }
        const csv = heading.concat(rows.map(rowMappingFn)).join("\n");
        const blob = new Blob([csv], {
          type: "text/csv;charset=utf-8",
        });
        const date = new Date();
        const dateStr = `${date.getFullYear()}_${
          date.getMonth() + 1
        }_${date.getDate()}_${date.getHours()}_${date.getMinutes()}`;
        saveAs(blob, `${filenamePrefix}_${dateStr}.csv`);
      }}
    >
      {label}
    </Button>
  );
}
function StatisticItem({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) {
  return (
    <ListItem dense sx={{ pt: 0, pb: 0 }} key={label}>
      <ListItemText
        primary={
          <>
            <strong>{label}:</strong> {value}
          </>
        }
      />
    </ListItem>
  );
}

const INCLUDED_TYPES = ["THERMAL", "VEGSTRATA"];
interface StatisticData {
  name: string;
  count: number;
  majority: number;
  masked_pixels: number;
  max: number;
  mean: number;
  median: number;
  min: number;
  minority: number;
  percentile_2: number;
  percentile_98: number;
  std: number;
  sum: number;
  unique: number;
  valid_percent: number;
  valid_pixels: number;
  suffix: string;
  layer: LayerResponse;
  histogram: number[][];
}

function VegPie({
  row,
  showTitle,
}: {
  row: StatisticData;
  showTitle?: boolean;
}) {
  return (
    <Pie
      options={{
        plugins: {
          title: {
            display: !!showTitle,
            text: row.name,
          },
          tooltip: {
            callbacks: {
              // append % to the chartjs label
              label: (context) => {
                const label = context.label as string;
                const value = context.parsed as number;
                return `${label}: ${value}%`;
              },
            },
          },
        },
      }}
      data={{
        labels: Object.values(row.layer.veg_strata || {}),
        datasets: [
          {
            label: "Vegetation Strata",
            data: row.histogram[0].map(
              (value) => `${oneDecimalPlace((value / row.count) * 100)}`,
            ),
            backgroundColor: row.layer.veg_strata_colour || [],
            borderColor: ["black"],
            borderWidth: 1,
          },
        ],
      }}
    />
  );
}

function Statistics() {
  const [searchParams] = useSearchParams();
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);

  const selectedGid = searchParams.get("selectedGid");
  const { data: geom } = useUIQuery<Geometries>(
    ["geometries", { id: selectedGid as string }],
    !!selectedGid,
  );
  const selectedVectorLayer = searchParams.get("selectedVectorLayer");
  const { data: vector } = useUIQuery<VectorResponse>(
    ["vector", { id: selectedVectorLayer as string }],
    !!selectedVectorLayer,
    "GET",
    { staleTime: 1000 * 60 * 60 },
  );

  // get geojson search param and parse into json
  const geojsonSerialized = searchParams.get("geojson");
  const geojson = useMemo(() => {
    if (geom?.geom) {
      // wrap in a feature collection
      return {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: geom.geom,
            properties: {},
          },
        ],
      } as FeatureCollection;
    }
    if (geojsonSerialized) {
      return JSON.parse(geojsonSerialized) as FeatureCollection;
    }
    return null;
  }, [geojsonSerialized, geom]);
  const statsTitle = useMemo(() => {
    if (geom?.geom) {
      const nameColumn = vector?.name_column;
      if (nameColumn) {
        return geom.additional_data[nameColumn];
      } else if (vector?.name) {
        return vector?.name + "Selected Feature";
      } else {
        return "Selected Feature";
      }
    }
    if (geojsonSerialized) {
      return "Drawn Feature";
    }
    return null;
  }, [geojsonSerialized, geom, vector]);
  const overlayIds = searchParams.getAll("overlay");
  const { selectedAcquisitions, isLoading: isAcquisitionsLoading } =
    useSelectedAcquisition();
  const [overlayIdToLayer] = useOverlays(selectedAcquisitions);
  const [layers, overlayIdsWithoutExcluded] = overlayIds.reduce(
    (acc, overlayId) => {
      const layer = overlayIdToLayer[overlayId];
      if (layer?.s3_path && INCLUDED_TYPES.includes(layer?.image_type)) {
        acc[0].push(layer);
        acc[1].push(overlayId);
      }
      return acc;
    },
    [[], []] as [LayerResponse[], string[]],
  );
  const { data, isLoading: isStatisticsLoading } = useStatistics(
    layers,
    geojson,
  );

  if (!geojson) {
    return null;
  }
  if ((isStatisticsLoading && layers?.length) || isAcquisitionsLoading) {
    const skeletonNumber = layers.length || 3;
    return (
      <>
        <Box sx={{ mt: 2, display: "flex" }}>
          <Skeleton width="115px" height="36px" />
          <Skeleton sx={{ ml: 1 }} width="80px" height="36px" />
        </Box>
        {Array.from(Array(skeletonNumber).keys()).map((i) => (
          <div key={i}>
            <Skeleton sx={{ mt: 1, mb: 0, pb: 0 }} width="100%" height="60px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
          </div>
        ))}
      </>
    );
  }
  const tableRows: StatisticData[] = (data
    ?.flatMap((featureCollection, index) => {
      return featureCollection?.response?.features?.map((feature) => {
        const statisticsKeys = feature.properties?.statistics
          ? Object.keys(feature.properties?.statistics)
          : [];
        const stats = feature.properties?.statistics[
          statisticsKeys[0]
        ] as StatisticData;
        return {
          ...(stats ? stats : {}),
          name: overlayIdToLayer[overlayIdsWithoutExcluded[index]]?.name,
          suffix:
            overlayIdToLayer[overlayIdsWithoutExcluded[index]]?.image_type ===
            "THERMAL"
              ? " °C"
              : "",
          layer: featureCollection?.layer,
        };
      });
    })
    ?.filter((statisticData) => !!statisticData) || []) as StatisticData[];
  const vegStrataRows = tableRows.filter(
    (row) => row.layer?.image_type === "VEGSTRATA",
  );
  const normalRows = tableRows.filter(
    (row) => row.layer?.image_type !== "VEGSTRATA",
  );
  return (
    <>
      {tableRows?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {normalRows?.length > 0 && (
            <DownloadButton
              filenamePrefix={`${statsTitle
                .toLowerCase()
                .replace(/\s/g, "_")}_statistics`}
              label="Stats"
              statsTitle={statsTitle}
              rows={normalRows}
            />
          )}
          {vegStrataRows?.length > 0 && (
            <DownloadButton
              filenamePrefix={`${statsTitle
                .toLowerCase()
                .replace(/\s/g, "_")}_veg_statistics`}
              label="Veg Strata"
              isVegStrata
              statsTitle={statsTitle}
              rows={vegStrataRows}
            />
          )}
          <Button
            startIcon={<LaunchIcon />}
            onClick={() => setIsTableModalOpen(true)}
          >
            Table
          </Button>
        </Box>
      )}

      <Typography
        sx={{ mt: 2, wordBreak: "break-all" }}
        component="h5"
        variant="h5"
      >
        {statsTitle}
      </Typography>
      <Box sx={{ pb: 4 }}>
        {tableRows.map((row, index) => {
          let data: ReactNode;
          if (
            overlayIdToLayer[overlayIdsWithoutExcluded[index]]?.image_type ===
            "VEGSTRATA"
          ) {
            data = <VegPie row={row} />;
          } else {
            data = (
              <List dense>
                <StatisticItem
                  label="Min"
                  value={`${oneDecimalPlace(row.min)}${row.suffix}`}
                />
                <StatisticItem
                  label="Max"
                  value={`${oneDecimalPlace(row.max)}${row.suffix}`}
                />
                <StatisticItem
                  label="Mean"
                  value={`${oneDecimalPlace(row.mean)}${row.suffix}`}
                />
              </List>
            );
          }
          return (
            <React.Fragment key={index}>
              <Typography
                sx={{ mt: 2, wordBreak: "break-all", fontSize: "1rem" }}
                component="h6"
                variant="h6"
              >
                {row.name}
              </Typography>
              {data}
            </React.Fragment>
          );
        })}
      </Box>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isTableModalOpen}
        onClose={() => setIsTableModalOpen(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {"Statistics for "}
          {statsTitle}
        </DialogTitle>
        <DialogContent>
          {normalRows?.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {HEADINGS.map((heading) => (
                      <TableCell key={heading}>{heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {normalRows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{statsTitle}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                        {oneDecimalPlace(row.min)} {row.suffix}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                        {oneDecimalPlace(row.max)} {row.suffix}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                        {oneDecimalPlace(row.mean)} {row.suffix}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Grid sx={{ mt: 2 }} container spacing={2}>
            {vegStrataRows.map((row) => (
              <Grid item xs={12} sm={6} lg={4}>
                <Paper sx={{ p: 2 }}>
                  <VegPie showTitle row={row} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {normalRows?.length > 0 && (
            <DownloadButton
              filenamePrefix={`${statsTitle
                .toLowerCase()
                .replace(/\s/g, "_")}_statistics`}
              statsTitle={statsTitle}
              label="Stats"
              rows={normalRows}
            />
          )}
          {vegStrataRows?.length > 0 && (
            <DownloadButton
              filenamePrefix={`${statsTitle
                .toLowerCase()
                .replace(/\s/g, "_")}_veg_statistics`}
              label="Veg Strata"
              statsTitle={statsTitle}
              isVegStrata
              rows={vegStrataRows}
            />
          )}
          <Button onClick={() => setIsTableModalOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export function AnalysisTab() {
  const [searchParams] = useSearchParams();
  const stableSetSearchParams = useStableSetSearchParams();
  useResetCompare();

  // copy searchParams
  const newSearchParams = new URLSearchParams(searchParams.toString());
  // set tab on newSearchParams to imagery
  newSearchParams.set("tab", "imagery");
  // get current location from react router
  const location = useLocation();
  // generate link using new search params and current location
  const imageryLink = `${location.pathname}?${newSearchParams.toString()}`;
  let instructions = (
    <Typography>
      Draw on the screen to generate analysis on the current active layers for
      that area. To change these layers, go to the{" "}
      <Link to={imageryLink}>Data tab</Link>. If you have selected a vector
      layer - click on the vectors to generate statistics for them.
    </Typography>
  );
  // if there is no "overlay" items in the search params, direct to the imagery tab
  if (searchParams.getAll("overlay").length === 0) {
    instructions = (
      <Typography>
        No overlay layers are selected. To select layers, go to the{" "}
        <Link to={imageryLink}>Data tab</Link>.
      </Typography>
    );
  }
  const { mapState } = useContext(MapContext);
  const [draw, setDraw] = useState<MapboxDraw | null>(null);
  useEffect(() => {
    if (mapState) {
      // create a new mapbox draw instance
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        styles: DRAW_STYLE,
        controls: {
          polygon: true,
          trash: true,
        },
      });
      // add the draw instance to the map
      mapState.addControl(draw);
      // don't use the react router search params because we only care about this on the initial render
      const geojsonSerialized = new URLSearchParams(window.location.search).get(
        "geojson",
      );
      if (geojsonSerialized) {
        const geojson = JSON.parse(geojsonSerialized) as FeatureCollection;
        draw.add(geojson);
      }
      setDraw(draw);
      // remove the draw instance from the map when the component unmounts
      return () => {
        mapState.removeControl(draw);
        setDraw(null);
      };
    }
  }, [mapState]);
  // effect for listening to draw events
  useEffect(() => {
    if (draw && mapState) {
      const changeEvent = (e: unknown) => {
        // get the geojson from the draw instance
        const geojson = draw.getAll();
        // delete all but the latest feature
        draw.delete(geojson.features.map((f) => f.id as string).slice(0, -1));
        // delete all but the latest
        geojson.features = geojson.features.slice(-1);
        // if there is a feature, set the geojson in the search params
        if (geojson.features.length > 0) {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set("geojson", JSON.stringify(geojson));
          stableSetSearchParams(newSearchParams);
        } else {
          // if there is no feature, remove the geojson from the search params
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.delete("geojson");
          stableSetSearchParams(newSearchParams);
        }
      };
      // listen to draw.create, draw.update, and draw.delete events
      mapState.on("draw.create", changeEvent);
      mapState.on("draw.update", changeEvent);
      mapState.on("draw.delete", changeEvent);
      // remove the event listeners when the component unmounts
      return () => {
        mapState.off("draw.create", changeEvent);
        mapState.off("draw.update", changeEvent);
        mapState.off("draw.delete", changeEvent);
      };
    }
  }, [draw, searchParams, stableSetSearchParams, mapState]);
  return (
    <>
      <Typography component="h5" variant="h6" sx={{ mb: 2 }}>
        Analysis
      </Typography>
      {instructions}
      <Statistics />
    </>
  );
}
